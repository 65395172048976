import React from "react";
import "./OrderTracking.scss";

function OrderTracking({ statuses }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 hh-grayBox pt45 pb20 border-box">
          <div className="row justify-content-between">
            {statuses.map((status, index) => (
              <div
                className={`order-tracking ${
                  status.completed
                    ? status.title === "Rejected"
                      ? "rejected"
                      : "completed"
                    : ""
                }`}
                key={index}
              >
                <span className="is-complete"></span>
                <p className="order-titles">
                  {status.title}
                  <br />

                  <span>{status.date}</span>
                </p>

                <br />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderTracking;
