import React, { useEffect, useState } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";
import AdminLogin from "../containers/Admin/AdminLogin/AdminLogin";
import Dashboard from "../containers/Admin/Dashboard/Dashboard";
import useAuth from "../../hooks/useAuth";
import useConfig from "../../hooks/useConfig";

import * as Storage from "../../store/LocalStorage";

import AuthLayout from "../layouts/Admin/AuthLayout";
import Products from "../containers/Admin/Products/Products";
import Orders from "../containers/Admin/Orders/Orders";
import Users from "../containers/Admin/Users/Users"; 
import Settings from "../containers/Admin/Settings/Settings";
import AddProducts from "../containers/Admin/Products/AddProducts/AddProducts";
import ProductDetails from "../containers/Admin/Products/ProductDetails/ProductDetails"; 
import EditProducts from "../containers/Admin/Products/EditProducts/EditProducts";
import PinCodes from "../containers/Admin/PinCodes/PinCodes";
import AddPinCode from "../containers/Admin/PinCodes/AddPinCode/AddPinCode";
import OrderDetails from "../containers/Admin/Orders/OrderDetails/OrderDetails";
import Categories from "../containers/Admin/Categories/Categories";
import AddNewCategories from "../containers/Admin/Categories/addNewCategories/AddNewCategories";

const AdminRoutes = () => {
  const [loading, setLoading] = useState(true);
  const { admin, updateUserContext } = useAuth();
  const navigate = useNavigate();

  const config = useConfig(); 

  const checkSession = () => {
    setLoading(true);
    let session = Storage.get(config.sessionKey);
    session = JSON.parse(session);
    if (session) {
      updateUserContext(session);
      setLoading(false);
    } else {
      navigate("/admin");
      setLoading(false);
    }
  }

  const hasLoaded = config.status === "success"
    || config.status === "error";
 
  useEffect(() => {
    if (!hasLoaded) return;
    checkSession();

    // eslint-disable-next-line
  }, [hasLoaded, config]);
  
  return (
    <>
      {hasLoaded &&
        !loading &&
        (admin ? (
          <AuthLayout>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/add-product" element={<AddProducts />} />
              <Route
                path="/products/product-details"
                element={<ProductDetails />}
              />
              <Route path="/products/edit-product" element={<EditProducts />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/orders/order-details" element={<OrderDetails />} />
              <Route path="/users" element={<Users />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/pin-codes" element={<PinCodes />} />
              <Route path="/pin-codes/add-pin-code" element={<AddPinCode />} />

              <Route path="/categories" element={<Categories />} />
              <Route path="/categories/add-category" element={<AddNewCategories />} />
            </Routes>
          </AuthLayout>
        ) : (
          <Routes>
            <Route path="/" exact element={<AdminLogin />} />
          </Routes>
        ))}
    </>
  );
};

export default AdminRoutes;
