import React from "react";
import Header from "./Header";
import "./Layout.scss";
import Footer from "./Footer";

export default function Layout(props) {
 
  return (
    <div className="layout"> 
      <div className="content-main">
        <div className="header-layout-customer">
          <Header />
        </div>
        <div className="content-layout">{props.children}</div>
        <div className="footer-layout">
          <Footer />
        </div>
      </div>
    </div>
  );
}
