import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "../../App.css";

import { Routes, Route } from "react-router-dom";
import Home from "../containers/Customer/Home/Home";
import Cart from "../containers/Customer/Cart/Cart";
import Login from "../containers/Customer/Login/Login";

import * as Storage from "../../store/LocalStorage";
import useCustomerAuth from "../../hooks/useCustomerAuth";
import useCustomerConfig from "../../hooks/useCutomerConfig";

import Layout from "../layouts/Customer/Layout"; 
import ProductDetails from "../containers/Customer/ProductDetails/ProductDetails";
import CartBillingDetails from "../containers/Customer/Cart/CartBillingDetails/CartBillingDetails";
import OrderComplete from "../containers/Customer/Cart/OrderComplete/OrderComplete"; 
import OrderDeliveryStatus from "../containers/Customer/Cart/TrackOrder/OrderDeliveryStatus/OrderDeliveryStatus";
import OrderDetails from "../containers/Customer/OrderDetails/OrderDetails";
import Categories from "../containers/Customer/Categories/Categories";
import AboutUs from "../containers/Customer/About-Us/AboutUs";
import ContactUs from "../containers/Customer/Contact-Us/ContactUs";
import AddressChange from "../containers/Customer/AddressChange/AddressChange";

import { CustomerPortalLoader } from "../../assets/loaders";


import CartContext from "../../context/CustomerCart";
import Privacy from "../containers/Customer/Privacy/Privacy";
import PaymentPolicy from "../containers/Customer/Payment-Policy/PaymentPolicy";
import CategoryBasedProducts from "../containers/Customer/CategoryBasedProducts/CategoryBasedProducts";

const CustomerRoutes = () => {
  const [loading, setLoading] = useState(true);
  const { updateCustomerUserContext } = useCustomerAuth();

  const config = useCustomerConfig();

  const checkSession = () => {
    setLoading(true);

    try {
      let session = Storage.get(config.customerSessionKey);
      session = JSON.parse(session);
      if (session) {
        updateCustomerUserContext(session);
      }
    } catch (e) {
      console.log(e.error)
      
    } finally {
      setLoading(false);
    }
  };

  const hasLoaded = config.status === "success" || config.status === "error";

  useEffect(() => {
    if (!hasLoaded) return;
    checkSession();

    // eslint-disable-next-line
  }, [hasLoaded, config]);
 

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      {!loading ? (
        <>
          <CartContext>
            <Layout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/cart" element={<Cart />} />
                <Route
                  path="/cart/billing-details"
                  element={<CartBillingDetails />}
                />
                <Route
                  path="/cart/order-complete"
                  element={<OrderComplete />}
                />
                <Route path="/track-order" element={<OrderDeliveryStatus />} />
                <Route path="/order-details" element={<OrderDetails />} />
                <Route
                  path="/products/product-details"
                  element={<ProductDetails />}
                />
                <Route path="/categories" element={<Categories />} />
                <Route path="/category-based-products" element={<CategoryBasedProducts />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/change-address" element={<AddressChange />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/payment-policy" element={<PaymentPolicy />} />
              </Routes>
            </Layout>
          </CartContext>
        </>
      ) : (
        <div className="customer-portal-loader">
          <CustomerPortalLoader />
        </div>
      )}
    </GoogleOAuthProvider>
  );
};

export default CustomerRoutes;
