import React from "react";
import "./Header.scss";
import useAuth from "../../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
 
import profileIMG from "../../../assets/images/img/profile.svg";

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();

  const getHeading = () => {
    switch (location.pathname) {
      case "/admin/dashboard":
        return "Dashboard";
      case "/admin/products":
        return "Products";
      case "/admin/products/add-product":
        return "Add Products";
      case "/admin/products/product-details":
        return "Product Details";
      case "/admin/products/edit-product":
        return "Edit Product";
      case "/admin/orders":
        return "Order List";
      case "/admin/orders/order-details":
        return "Order Details";
      case "/admin/users":
        return "Users";
      case "/admin/testimonials":
        return "Testimonials";
      case "/admin/testimonials/add-testimonials":
        return "Add Testimonials";
      case "/admin/settings":
        return "Settings";
      case "/admin/pin-codes":
        return "Pin Codes";
      case "/admin/pin-codes/add-pin-code":
        return "Add PinCode";
      case "/admin/categories":
        return "Categories";
      case "/admin/categories/add-category":
        return "Add Categories"; 

      default:
        return "Dashboard";
    }
  };

  const { admin } = useAuth();

  return (
    <div className="header-main">
      <div className="left-header">
        <h4>{getHeading()}</h4>
      </div>

      <div className="right-header"> 
        <div className="image" onClick={() => navigate("/admin/settings")}>
          <img src={profileIMG} alt="profile" />
          <h4>{admin?.name}</h4>
        </div>
      </div>
    </div>
  );
}
