import React, { useState } from "react";
import "./Settings.scss";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import ErrorMessage from "../../../../assets/elements/ErrorMessage";
import PrimaryButton from "../../../../assets/elements/PrimaryButton";
import { FormGroup, Input, Label } from "reactstrap";  
 
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

import MainServices from "../../../../services/MainServices";
import axios from "axios";
import {
  notifyError,
  notifySuccess, 
} from "../../../../assets/elements/Toaster"; 
import { useNavigate } from "react-router-dom";

const addProductSchema = Yup.object().shape({
  previous_password: Yup.string().required("Old Password is Required"),
  new_password: Yup.string().required("New Password is Required"),
  confirm_password: Yup.string()
    .required("Confirm Password is Required")
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});

const Settings = () => { 

  const navigate = useNavigate();
 
  const [type, setType] = useState("password");
  const [newType, setNewType] = useState("password");
  const [confirmNewType, setConfirmNewType] = useState("password");

  const [upadteLoading, setUpdateLoading] = useState(false);

  

  const onChangeType = () => {
    setType(type === "password" ? "text" : "password");
  };
  const onChangeNewType = () => {
    setNewType(newType === "password" ? "text" : "password");
  };
  const onChangeConfirmNewType = () => {
    setConfirmNewType(confirmNewType === "password" ? "text" : "password");
  };

  const { updatePassword } = MainServices();

  const updateAdminPassword = async (values) => {
    setUpdateLoading(true);
    const details = {
      oldPassword: values?.previous_password,
      newPassword: values?.new_password,
    };

    await axios
      .all([updatePassword(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            console.log(res);
            setUpdateLoading(false);
            notifySuccess("Successfully Updated Admin Credentials");
            navigate(-1)
          } else {
            console.log(message);
            notifyError(message);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        setUpdateLoading(false);
        notifyError(error);
      });
    setUpdateLoading(false);
  };

  return (
    <div className="main-settings-container">
      <div className="settings-form">
        <Formik
          initialValues={{
            previous_password: "",
            new_password: "",
            confirm_password: "",
          }}
          onSubmit={(values) => {
            updateAdminPassword(values);
          }}
          validationSchema={addProductSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            touched,
          }) => (
            <Form>  
              <h4>Update Admin Credentials: </h4>
              <FormGroup>
                <Label className="input-headings">Previous Password: </Label>
                <div
                  className="password-input-group"
                  style={{ width: "320px" }}
                >
                  <Input
                    name="previous_password"
                    className="text-input add-product-input"
                    value={values.previous_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Password"
                    type={type}
                  />
                  <div className="eye-icon" onClick={onChangeType}>
                    {type === "password" ? (
                      <AiOutlineEye size={22} />
                    ) : (
                      <AiOutlineEyeInvisible size={22} />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="previous_password"
                />
              </FormGroup>
              <FormGroup>
                <Label className="input-headings">New Password : </Label>
                <div
                  className="password-input-group"
                  style={{ width: "320px" }}
                >
                  <Input
                    name="new_password"
                    className="text-input add-product-input"
                    value={values.new_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Password"
                    type={newType}
                  />
                  <div className="eye-icon" onClick={onChangeNewType}>
                    {newType === "password" ? (
                      <AiOutlineEye size={22} />
                    ) : (
                      <AiOutlineEyeInvisible size={22} />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="new_password"
                />
              </FormGroup>

              <FormGroup>
                <Label className="input-headings">Confirm Password : </Label>
                <div
                  className="password-input-group"
                  style={{ width: "320px" }}
                >
                  <Input
                    name="confirm_password"
                    className="text-input add-product-input"
                    value={values.confirm_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Password"
                    type={confirmNewType}
                  />
                  <div className="eye-icon" onClick={onChangeConfirmNewType}>
                    {confirmNewType === "password" ? (
                      <AiOutlineEye size={22} />
                    ) : (
                      <AiOutlineEyeInvisible size={22} />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="confirm_password"
                />
              </FormGroup>

              <div className="login-btn">
                <PrimaryButton
                  style={{
                    width: "330px",
                    padding: ".650rem 0",
                    fontFamily: "Montserrat-Regular",
                    background: "#005478",
                    fontSize: "1rem",
                    borderRadius: "22px",
                  }}
                  type="submit"
                  title="Update Password"
                  isLoading={upadteLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Settings;
