import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import Toaster from './assets/elements/Toaster';
import Main from './Main';
import { ConfigProvider } from './context/Config';
import { CustomerConfigProvider } from "./context/UserConfig"
import { AuthProvider } from './context/Auth';
import { UserAuthProvider } from "./context/UserAuth"  

function App() {
  return (
    <CustomerConfigProvider>
      <ConfigProvider>
        <UserAuthProvider>
          <AuthProvider>
            <Router>
              <Toaster />
              <Main />
            </Router>
          </AuthProvider>
        </UserAuthProvider>
      </ConfigProvider>
    </CustomerConfigProvider>
  );
}  

export default App;
