import React, { useState, useEffect } from "react";
import "./Users.scss";
import { LazyLoader } from "../../../../assets/loaders";
import MainServices from "../../../../services/MainServices";
import axios from "axios";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import CustomPagination from "../../../../assets/pagination/Pagination";
import { filtersToUrlParams } from "../../../../assets/functions/filters";

const Users = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const { getUsersCount } = MainServices();

  //Pagination
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  const [totalCount, setTotalCount] = useState(0);

  //pagination functions
  const onPageChange = (page) => {
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
    });
    navigate({ search });
  };

  const onRowsChange = (rows) => {
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
    });
    navigate({
      search,
    });
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("DD MMMM YYYY");
  };

  const getUsers = async ({ page, limit }) => {
    setLoading(true);

    const details = { page: page, limit: limit };

    await axios
      .all([getUsersCount(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let count = resData?.data?.count;
          if (resData.success) {
            resData = resData.data ? resData?.data?.data : [];
            setUsers(resData);
            setTotalCount(count);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
        setLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("Error occured in retreving Users");
      });
  };

  useEffect(() => {
    let pagination = {
      limit: changedParams?.limit || 10,
      page: changedParams?.page || 1,
    };
    setPagination(pagination);
    getUsers({ page: pagination.page, limit: pagination.limit });
    // eslint-disable-next-line
  }, [changedParams.limit, changedParams.page]);

  return (
    <div className="main-users-container">
      <div className="users-table">
        {loading ? (
          <LazyLoader />
        ) : (
          <table>
            <thead>
              <tr>
                <th> NAME</th>
                <th>EMAIL</th>
                <th>CREATED AT</th>
              </tr>
            </thead>
            <tbody>
              {users.map((item) => (
                <tr key={item.id}>
                  <td style={{ textTransform: "capitalize" }}>
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight: ".350rem",
                      }}
                      src={item?.profile_picture}
                      alt=""
                    />
                    {console.log(item?.profile_picture)}
                    {item?.name}
                  </td>
                  <td>{item?.email}</td>

                  <td style={{ maxWidth: "300px" }}>
                    {formatDate(item?.createdAt)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <CustomPagination
          currentPage={pagination.page}
          rowsPerPage={pagination.limit}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onRowsPerPage={onRowsChange}
        />
      </div>
    </div>
  );
};

export default Users;
