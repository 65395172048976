import React, { useEffect } from "react";
import "./PaymentPolicy.scss";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-container-payment">
      <h5 className="heading-text">Payment Policy for Furniture King</h5>

      <div className="main-content">
        <h6>Refund (Eligibility): </h6>
        <p>
          <span style={{ fontWeight: "800" }}>
            Damaged or Defective Items :{" "}
          </span>
          If you receive a damaged or defective product, please contact us
          within 7 days of delivery. We'll arrange for a replacement.
        </p>
        <p style={{ fontWeight: "600" }}>
          Condition apply. <br /> - The item must be in its original condition.
        </p>
      </div>
      <div className="main-content">
        <h6>Process : </h6>
        <ol>
          <li>
            <p>
              <span style={{ fontWeight: "800" }}>Contact Us : </span>
              Reach out to our customer support team via email to us on
              furnitureking987@gmail.com or through our website.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: "800" }}>Provide Details : </span>
              Include your order number, a brief description of the issue, and
              relevant photos (if applicable).
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: "800" }}>Approval : </span>
              We'll review your request and notify you of the approval status.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: "800" }}>Return : </span>
              If approved, ship the item back to us. We'll provide return
              instructions.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: "800" }}>Refund : </span>
              Once we receive the returned item, we'll process your refund{" "}
              <strong> within 5 business days.</strong>
            </p>
          </li>
        </ol>
      </div>
      <div className="main-content">
        <h6>Exceptions : </h6>
        <ol>
          <li>
            <p>
              <span style={{ fontWeight: "800" }}>Custom Orders : </span>
              Custom-made furniture is non-refundable unless it arrives damaged
              or defective.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: "800" }}>Clearance Items : </span>
              Clearance or sale items are final sale and not eligible for
              refunds.
            </p>
          </li>
        </ol>
      </div>
      <div className="main-content">
        <h6>Contact Us : </h6>
        <p>
          If you have any questions or need assistance, feel free to reach out
          to our customer support team.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
