import React from "react";
import "./QuantitySelector.scss";

const QuantitySelector = ({
  cartId,
  quantity,
  decreaseQuantity,
  increaseQuantity,
}) => {
  return (
    <div className="quantity">
      <span
        href="#"
        className="quantity__minus"
        onClick={() => decreaseQuantity(cartId, quantity)}
      >
        <span>-</span>
      </span>
      <input
        name="quantity"
        type="text"
        className="quantity__input"
        value={quantity}
        readOnly
      />
      <span
        href="#"
        className="quantity__plus"
        onClick={() => increaseQuantity(cartId, quantity)}
      >
        <span>+</span>
      </span>
    </div>
  );
};

export default QuantitySelector;
