import { Bars, TailSpin, Oval } from "react-loader-spinner";
import "./Loaders.scss";

export const LazyLoader = () => {
  return (
    <div className="loader-main">
      <Bars
        height="50"
        width="40"
        color="#005478"
        ariaLabel="bar-loading"
        visible={true}
      />
    </div>
  );
};

export const CustomerLazyLoader = () => {
  return (
    <div className="loader-main">
      <Bars
        height="50"
        width="40"
        color="rgb(65, 133, 63)"
        ariaLabel="bar-loading"
        visible={true}
      />
    </div>
  );
};

export const CustomerPortalLoader = () => {
  return (
    <Oval
      visible={true}
      height="80"
      width="80"
      color="rgb(65, 133, 63)"
      ariaLabel="oval-loading" 
    />
  );
};

export const ButtonLoader = ({ color }) => {
  return (
    <TailSpin
      height="1.5rem"
      color={color ? color : "white"}
      radius="2"
      wrapperClass="loader-button"
      visible={true}
    />
  );
};
