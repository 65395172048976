import React, { useRef } from "react";
import "./ContactUs.scss";
import Line from "../../../../assets/images/img/cus-line.svg";

const ContactUs = () => {
  const formRef = useRef();
  const handleContactUsClick = (e) => {
    const form = formRef.current;
    e.preventDefault();

    const name = document.querySelector('input[name="name"]').value;
    const email = document.querySelector('input[name="email"]').value;
    const subject = document.querySelector('input[name="subject"]').value;
    const message = document.querySelector('textarea[name="message"]').value;

    const mailtoLink = `mailto: furnitureking987@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\n\n${message}`
    )}`;

    window.location.href = mailtoLink;
    form.reset();
  };

  return (
    <div className="contact-us-container">
      <div className="contact-us-header">
        <h5>Contact Us</h5>
      </div>

      <div className="contact-us-content">
        <div className="contact-us-headings">
          <h6>
            <img src={Line} alt="" />
            Get In Touch
          </h6>
          <p>
            Have questions or need assistance? Reach out to us at{" "}
            <span style={{ color: "blue" }}>furnitureking987@gmail.com</span>{" "}
            We'd love to hear from you!
          </p>

          <h4>
            Your email address will not be published. <br /> Required fields are
            marked*
          </h4>
        </div>

        <div className="form-div">
          <div className="contact-form">
            <form ref={formRef} onSubmit={handleContactUsClick}>
              <div className="input-container">
                <label htmlFor="name">Your Name*</label> <br />
                <input
                  type="text"
                  name="name"
                  placeholder="Enter your name..."
                  required
                />
              </div>
              <div className="input-container">
                <label htmlFor="email">Email*</label> <br />
                <input
                  type="email"
                  name="email"
                  placeholder="example@gmail.com"
                  required
                />
              </div>
              <div className="input-container">
                <label htmlFor="subject">Subject*</label> <br />
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject..."
                  required
                />
              </div>
              <div className="input-container">
                <label htmlFor="message">Your Message*</label> <br />
                <textarea
                  name="message"
                  placeholder="Enter your message..."
                  required
                />
              </div>

              <button type="submit" className="send-message">
                Send Message
              </button>
            </form>
          </div>

          <div className="shop-address">
            <h4>Address</h4>
            <p>
              10-52/1, Pothireddy Pally, <br /> Near Rural Police Station,{" "}
              <br />
              Sangareddy -502001. Telangana
            </p>

            <h4>Contact</h4>
            <p>+91 9849022026</p>

            <h4>Timings</h4>
            <p>
              Monday - Friday <br />
              10:00 - 20:00
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
