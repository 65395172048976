import React from "react";
import "./Style.scss";
import { Button } from "reactstrap"; 
import { ButtonLoader } from "../loaders";

export default function PrimaryButton({ isLoading, title, ...props }) {
  return (
    <div className="save-btn-div">
      <Button disabled={isLoading} className="save-btn" {...props}>
        {isLoading ? <ButtonLoader /> : (title)}
      </Button>
    </div>
  );
}
