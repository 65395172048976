import React from "react";

import { Routes, Route} from "react-router-dom"; 
import AdminRoutes from "./AdminRoutes"; 
 
import CustomerRoutes from "./CustomerRoutes";
 
const CustomRoutes = () => {
   
  return (
    <Routes>
      {/* Customer Routes */}
      <Route path="/*" exact element={<CustomerRoutes />} />

      {/* Admin routes */}
      <Route path="/admin/*" element={<AdminRoutes />} />
 
    </Routes>
  );
};

export default CustomRoutes;
