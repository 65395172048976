import React, { useEffect } from "react";
import "./Privacy.scss";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-container">
      <h5 className="heading-text">Privacy Policy for Furniture King</h5>

      <div className="main-content">
        <h6>Introduction : </h6>
        <p>
          Welcome to Furniture King! We value your privacy and are committed to
          protecting your personal information. This privacy policy explains how
          we collect, use, and safeguard your data when you visit our website.
        </p>
      </div>
      <div className="main-content">
        <h6>Information We Collect : </h6>
        <ul>
          <li>
            <p>
              <span style={{ fontWeight: "800" }}>Personal Information : </span>
              When you browse our website or make a purchase, we may collect
              personal details such as your name, email address, phone number,
              and shipping address.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: "800" }}>Usage Data : </span>
              We gather information about your interactions with our site,
              including IP addresses, browser type, and pages visited.
            </p>
          </li>
        </ul>
      </div>
      <div className="main-content">
        <h5>How We Use Your Information</h5>
        <h6>We use your data for the following purposes : </h6>
        <ul>
          <li>
            <p>
              <span style={{ fontWeight: "800" }}>Order Processing : </span>
              To process and fulfill your orders.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: "800" }}>Communication : </span>
              To send order updates, newsletters, and promotional offers.
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontWeight: "800" }}>Analytics : </span>
              To improve our website and enhance user experience.
            </p>
          </li>
        </ul>
      </div>
      <div className="main-content">
        <h6>Data Security : </h6>
        <p>
          We take security seriously. Your information is stored securely and
          protected against unauthorized access.
        </p>
      </div>
      <div className="main-content">
        <h6>Cookies : </h6>
        <p>
          We use cookies to enhance your browsing experience. You can adjust
          your browser settings to manage cookies.
        </p>
      </div>

      <div className="main-content">
        <h6>Third-Party Services : </h6>
        <p>
          We may use third-party services (e.g., payment gateways) that have
          their own privacy policies. Please review those policies.
        </p>
      </div>

      <div className="main-content">
        <h6>Your Rights : </h6>
        <p>
          You have the right to access, update, or delete your personal
          information. Contact us at (email to: furnitureking987@gmail.com) for
          assistance.
        </p>
      </div>

      <div className="main-content">
        <h6>Changes to this Policy: </h6>
        <p>
          We may update this policy periodically. Check our website for the
          latest version.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
