import {
  createContext,
  useContext,
  useReducer, 
  useState,
  useEffect
} from "react";
 
import axios from "axios";
import { cartReducer } from "./CustomerCartReducer"; 
import MainServices from "../services/MainServices";

const Cart = createContext();

const CartContext = ({ children }) => { 
  const { getCartItem } = MainServices();
  const [cartItems, setCartItems] = useState([]);

  const [state, dispatch] = useReducer(cartReducer, {
    cart: cartItems,
  });

  useEffect(() => {
    getCartOfUser();
    // eslint-disable-next-line
  }, [])

  //get cart api
  const getCartOfUser = async () => { 
    try {
      const details = {};
      const res = await axios.all([getCartItem(details)]);
      if (res[0]) {
        let resData = res[0].data.data ? res[0].data.data.cart : [];
        console.log("resData: ", resData);

        setCartItems(resData);
        dispatch({
          "type": "SET_CART",
          payload: resData,
        })
      }
    } catch (error) {
      console.error("An error occurred in retrieving Carts", error);
    } finally { 
    }
  };

  //const prevCartItems = JSON.parse(Storage.get("customerCartItems"));


  return <Cart.Provider value={{ state, dispatch }}>{children}</Cart.Provider>;
};

export const CartState = () => {
  return useContext(Cart);
};

export default CartContext;
