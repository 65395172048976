import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminLogin.scss";

import ErrorMessage from "../../../../assets/elements/ErrorMessage";
import PrimaryButton from "../../../../assets/elements/PrimaryButton";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { FormGroup, Input } from "reactstrap";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import MainServices from "../../../../services/MainServices";
import {
  notifyError,
  notifySuccess,
} from "../../../../assets/elements/Toaster";
import useConfig from "../../../../hooks/useConfig";
import useAuth from "../../../../hooks/useAuth";

import * as Storage from "../../../../store/LocalStorage";

import LoginBanner from "../../../../assets/images/img/admin-banner.svg"; 

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is Required")
    .email("Please enter a valid Email"),
  password: Yup.string()
    .required("Password is required")
    .min(4, "Atleast six(4) Characters"),
});

const AdminLogin = () => {
  const [type, setType] = useState("password");
  const [isLoginInProgress, setIsLoginInProgress] = useState();
  const [errorMessageOnLogin, setErrorMessageOnLogin] = useState();

  const { updateUserContext } = useAuth();
  const config = useConfig();

  const { adminSignin } = MainServices();
  const navigate = useNavigate();

  const onChangeType = () => {
    setType(type === "password" ? "text" : "password");
  };

  //login api
  const handleAdminLogin = async (values) => {
    setIsLoginInProgress(true);
    setErrorMessageOnLogin("");
    const details = {
      email: values.email,
      password: values.password,
    };

    await axios
      .all([adminSignin(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            let access_token = resData?.data?.data?.access_token;
            let userDetails = resData?.data?.data || {};
            userDetails = {
              access_token,
              _id: userDetails?._id,
              email: userDetails?.email,
              name: userDetails?.name,
              role: userDetails?.role,
            };
            navigate("/admin/dashboard");
            notifySuccess("Admin Login Successfully");
            Storage.set(config.sessionKey, JSON.stringify(userDetails));

            updateUserContext(userDetails);
            setIsLoginInProgress(false);
          } else {
            let errors = resData?.details || [];
            console.log(errorMessageOnLogin);
            setErrorMessageOnLogin(errors);
            notifyError(message);
            setIsLoginInProgress(false);
          }
        } else {
          setIsLoginInProgress(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in User Signin");
      });
  };

  return (
    <div className="main-admin-container">
      <div className="left-container">
        <div className="image-container">
          <img className="banner-image" src={LoginBanner} alt="login-banner" />
        </div>
      </div>

      <div className="right-container"> 
        <h1 className="logo-text">Hindwala.com</h1>

        <h4
          style={{
            textAlign: "center",
            fontFamily: "Montserrat-Medium",
            fontSize: "30px",
            paddingBottom: "2rem",
          }}
        >
          Admin Login
        </h4>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={(values) => {
            handleAdminLogin(values);
          }}
          validationSchema={loginSchema}
        >
          {({ values, errors, handleBlur, handleChange, touched }) => (
            <Form>
              <FormGroup>
                <Input
                  className="text-input"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                />
                <ErrorMessage errors={errors} touched={touched} name="email" />
              </FormGroup>
              <FormGroup>
                <div className="password-input-group">
                  <Input
                    name="password"
                    className="text-input"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Password"
                    type={type}
                  />
                  <div className="eye-icon" onClick={onChangeType}>
                    {type === "password" ? (
                      <AiOutlineEye size={22} />
                    ) : (
                      <AiOutlineEyeInvisible size={22} />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="password"
                />
              </FormGroup>
              <div className="login-btn">
                <PrimaryButton
                  style={{
                    width: "100%",
                    padding: "1rem",
                    borderRadius: "0px",
                    fontFamily: "Montserrat-Regular",
                    background: "#000",
                    fontSize: "1.2rem",
                  }}
                  type="submit"
                  title="Login"
                  isLoading={isLoginInProgress}
                />
              </div>
            </Form>
          )}
        </Formik>
 
      </div>
    </div>
  );
};

export default AdminLogin;
