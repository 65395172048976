import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import * as Storage from "../store/LocalStorage";
import useCustomerConfig from "../hooks/useCutomerConfig";


const initialState = {
  isAuthenticated: false,
  user: null,
};

const reducers = {
  UPDATE: (state, action) => {
    const user = action.payload;
    return { ...state, user, isAuthenticated: true };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  
};

const reducer = (state, action) =>
  reducers[action.type] ? reducers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  logout: () => Promise.resolve(),
  updateCustomerUserContext: () => Promise.resolve(),
});

UserAuthProvider.propTypes = {
  children: PropTypes.node,
};

function UserAuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState); 
  const config = useCustomerConfig();

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    Storage.remove(config.customerSessionKey);
    Storage.remove(config.customerCartItems);
  };

  const updateCustomerUserContext = (data) => { 
     dispatch({ type: "UPDATE", payload: data });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout,
        updateCustomerUserContext, 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, UserAuthProvider };
