const apiendpoint = "http://localhost:3000";

let apiServices = {
  /* Admin end point */
  adminSignin: `${apiendpoint}/api/login`,
  addProduct: `${apiendpoint}/api/add_product`,
  getProducts: `${apiendpoint}/api/get_product`,
  getProductsStats: `${apiendpoint}/api/get_stats_of_product_by_status`,
  getProductById: `${apiendpoint}/api/get_product_by_product_id`,
  updateProductById: `${apiendpoint}/api/update_product`,
  deleteProductById: `${apiendpoint}/api/delete_product`,
  getUsersCount: `${apiendpoint}/api/get_user_count`,
  updatePassword: `${apiendpoint}/api/update_password`,
  getAllPincodes: `${apiendpoint}/api/get_all_pincode`,
  addPincode: `${apiendpoint}/api/add_pincode`,
  deletePincode: `${apiendpoint}/api/delete_pincode`,
  getAllOrder: `${apiendpoint}/api/get_all_order`,
  updateOrderStatus: `${apiendpoint}/api/update_order_status`,
  getAdminOrderById: `${apiendpoint}/api/get_admin_order`,
  getSalesAmount: `${apiendpoint}/api/get_total_sales_amount`,
  getAdminProduct: `${apiendpoint}/api/get_product_by_admin`,
  getStatsOfAllOrderStatus: `${apiendpoint}/api/get_stats_of_all_order_status`,
  getSalesStatsByMonth: `${apiendpoint}/api/get_monthly_sales`,
  getSalesStatsByYear: `${apiendpoint}/api/get_yearly_sales`,
  getAllCategories: `${apiendpoint}/api/get_all_category`,
  addCategory: `${apiendpoint}/api/add_category`,
  deleteCategory: `${apiendpoint}/api/delete_category`,


  /* Customer end point */
  addCustomer: `${apiendpoint}/api/add_user`,
  addItemToCart: `${apiendpoint}/api/add_cart_item`,
  getCartItem: `${apiendpoint}/api/get_cart_item`,
  deleteCartItem: `${apiendpoint}/api/delete_cart_item`,
  updateCartItem: `${apiendpoint}/api/update_cart_item`,
  placeOrder: `${apiendpoint}/api/place_order`,
  getUser: `${apiendpoint}/api/get_user`,
  getAddressOfUser: `${apiendpoint}/api/get_address_by_user`,
  updateAddressOfUser: `${apiendpoint}/api/update_address_by_user`,
  getOrderOfUser: `${apiendpoint}/api/get_orders_based_on_user`,
  confirmOrder: `${apiendpoint}/api/order`,
  getPaymentStatus: `${apiendpoint}/api/payment_status`,
};

export default apiServices;