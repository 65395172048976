import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import * as Storage from "../../../../store/LocalStorage";
import "./ProductDetails.scss"; 

import axios from "axios";

import { IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom"; 
 
import { CustomerLazyLoader } from "../../../../assets/loaders";
import MainServices from "../../../../services/MainServices";
import { CartState } from "../../../../context/CustomerCart";
import { ButtonLoader } from "../../../../assets/loaders";
import { notifySuccess, notifyWarn } from "../../../../assets/elements/Toaster";
import useCustomerConfig from "../../../../hooks/useCutomerConfig";
import useCustomerAuth from "../../../../hooks/useCustomerAuth";
 

const ProductDetails = () => {
  const navigate = useNavigate();
  const config = useCustomerConfig();
  const location = useLocation();
  const product_id = new URLSearchParams(location.search).get("productId"); 
 
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [images, setImages] = useState([]);

  const [buttonLoading, setButtonLoading] = useState(false);

  const {
    state: { cart },
    dispatch,
  } = CartState();

  const { user } = useCustomerAuth();

  console.log(product);

  const { getProductById, addItemToCart } = MainServices();

  useEffect(() => {
    window.scrollTo(0, 0);
    getProductItemById(product_id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    Storage.set(config.customerCartItems, JSON.stringify(cart));
  }, [cart, config.customerCartItems]);

  

  //get product by id api
  const getProductItemById = async (productId) => {
    setLoading(true);
    const details = {
      productId: productId,
    };

    await axios
      .all([getProductById(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];

          if (resData.success) {
            resData = resData.data ? resData?.data?.data : [];
            // console.log(resData);
            setProduct(resData);
            // setImageItems(resData?.images);
            const imageItems = resData.images.map((image) => ({
              original: image,
              thumbnail: image,
            }));

            setImages(imageItems);
            setLoading(false);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in retreving Product");
      });
  };

  //add to cart api
  const handleAddToCart = async () => {
    setButtonLoading(true);

    const details = {
      product_id: product?._id,
      quantity: 1,
    };

    try {
      const res = await axios.all([addItemToCart(user?.access_token, details)]);
      if (res[0]) {
        console.log(res[0].success);
        if (res[0].success) {
          const cartItem = res[0].data.data;
          dispatch({
            type: "ADD_TO_CART",
            payload: cartItem,
          });
          const updatedCart = [...cart, { ...product, qty: 1 }];
          Storage.set(config.customerCartItems, JSON.stringify(updatedCart));

          notifySuccess(`${product?.name} added to the cart.`);
           
        } else {
          notifyWarn("Item Already in Cart");
        }
      }
    } catch (error) {
      console.error("Error adding to cart", error);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <CustomerLazyLoader />
      ) : (
        <>
          <div className="main-product-details">
            <div className="back-btn" onClick={() => navigate(-1)}>
              <IoIosArrowBack size={23} color="#000" />
            </div>
            <div className="image-gallery-container">
              <ImageGallery
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
              />
              <div className="product-detail">
                <span className="offer">{product?.discount}% OFF</span>
                <p style={{ marginTop: "1rem" }}>
                  Category : {product?.category ? product?.category?.categoryName : ""}
                </p>
                <h4>{product?.name}</h4> 
                <h4>Price - {product?.price?.toLocaleString()}/-</h4>
                <h6>Offer Price - {product?.offer_price?.toLocaleString()}</h6>

                <p>Description - {product?.description}</p>
                {product?.in_stock === true ? (
                  <p style={{ color: "rgb(0, 119, 12)", fontWeight: "700" }}>
                    In Stock
                  </p>
                ) : (
                  <></>
                )}
                <div className="cta-btns-container">
                  <div className="cta-buttons-div">
                    {cart?.some((p) => p.product_id?._id === product?._id) ? (
                      <h6
                        style={{ fontSize: "14px", color: "rgb(0, 119, 12)" }}
                      >
                        Product in Cart!
                      </h6>
                    ) : (
                      <button
                        className="add-to-cart"
                        onClick={() => handleAddToCart()}
                      >
                        {buttonLoading ? <ButtonLoader /> : "Add To Cart"}
                      </button>
                    )}
                  </div>
                </div>

                {/*  <img
                  style={{ margin: "2rem 0" }}
                  src={HorizontalLine}
                  alt=""
                  className="border-line"
                />

                <div className="tags">
                  <h4>Tags : </h4>
                  <p>Furniture, Office, Chair</p>
                </div>

                <div className="share">
                  <h4>Share : </h4>
                  <div>
                    <FaFacebook size={33} className="share-icon" />
                    <RiInstagramFill size={33} className="share-icon" />
                    <RiWhatsappFill size={33} className="share-icon" />
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/*  <div className="description-tabs">
            <Tabs tabs={tabData} />
          </div> */}
        </>
      )}
    </>
  );
};

export default ProductDetails;
