import React, { useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

import useCutomerAuth from "../../../../hooks/useCustomerAuth";
import * as Storage from "../../../../store/LocalStorage";
import useCustomerConfig from "../../../../hooks/useCutomerConfig";
import { useNavigate } from "react-router-dom";

import LogoIcon from "../../../../assets/images/img/logo.svg";
import "./Login.scss";

import MainServices from "../../../../services/MainServices"; 

const Login = () => {
  const navigate = useNavigate();
  const { updateCustomerUserContext } = useCutomerAuth();
  const config = useCustomerConfig();

  const { addCustomer } = MainServices();
 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  const sendUserToBackend = async (access_token) => {
    const details = {
      token: access_token,
    };
    try {
      await addCustomer(details);
      console.log("Token sent to backend");
    } catch (error) {
      console.error("Error sending token to backend:", error);
    }
  };

  return (
    <div className="main-login-container">
      <div className="login-content">
        <div className="text-content">
          <div className="logo">
            <img src={LogoIcon} alt="" />
          </div>
          <h6>Sign In To Your Account</h6>
        </div>
        <GoogleLogin
          size="large"
          onSuccess={async (credentialResponse) => {
            const access_token = credentialResponse?.credential;
            //  console.log(access_token);
            const credentialResponseDecoded = jwtDecode(
              credentialResponse.credential
            );
            const userData = { credentialResponseDecoded, access_token }; 
            Storage.set(config.customerSessionKey, JSON.stringify(userData));
            await sendUserToBackend(userData?.access_token);
            await updateCustomerUserContext(userData);

            navigate("/");
            window.location.reload();
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
        <p
          style={{
            marginTop: "1rem",
            fontFamily: "Montserrat-Medium",
            fontSize: "16px",
          }}
        >
        {" "}  Continue without signin?
          <span
            style={{
              color: "hsla(122, 60%, 21%, 0.95)",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => navigate("/categories")}
          >
            Explore
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
