import React, { useEffect, useState } from "react";
import "./OrderDeliveryStatus.scss";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "react-step-progress-bar/styles.css";

import OrderTracking from "../../../../../../assets/elements/Customer/OrderTracking";
import ShippingSvg from "../../../../../../assets/images/img/cus-shiping.svg";
import PaymentSvg from "../../../../../../assets/images/img/cus-payment.svg";
import SupportSvg from "../../../../../../assets/images/img/cus-suport.svg";
import axios from "axios";
import MainServices from "../../../../../../services/MainServices";
import { CustomerLazyLoader } from "../../../../../../assets/loaders";

const OrderDeliveryStatus = () => {
  const navigate = useNavigate(); 
  const [loading, setLoading] = useState([]);

  const { getOrderOfUser } = MainServices();

  const [orderDetails, setOrderDetails] = useState([]);

  const getOrder = async () => {
    setLoading(true);
    try {
      const details = {};
      const res = await axios.all([getOrderOfUser(details)]);
      if (res[0]) {
        let resData = res[0].data.data ? res[0].data.data.order : [];
        const order = resData.map((order) => setOrderDetails(order));
      }
    } catch (error) {
      console.error("An error occurred in retrieving Orders", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrder();
  }, []);

  const normalizeStatus = (status) => {
    const statusMap = {
      Inprogress: "In Progress",
      "Order Placed": "Order Placed",
      Accepted: "Accepted",
      "In Progress": "In Progress",
      "Out For Delivery": "Out For Delivery",
      Delivered: "Delivered",
      Rejected: "Rejected",
    };
    return statusMap[status] || status;
  };

  const getStatuses = (currentStatus) => {
    const statusOrder = [
      "Order Placed",
      "In Progress",
      "Out For Delivery",
      "Delivered",
      "Completed",
    ];
    const normalizedStatus = normalizeStatus(currentStatus);
    if (normalizedStatus === "Rejected") {
      return [
        { title: "Order Placed", completed: true },
        { title: "Rejected", completed: true },
      ];
    }
    const currentStatusIndex = statusOrder.indexOf(normalizedStatus);

    return statusOrder.map((status, index) => ({
      title: status,
      completed: index <= currentStatusIndex,
    }));
  };

  const statuses = getStatuses(orderDetails?.status);
 

  return (
    <div className="main-order-status-container">
      <div className="header">
        <IoChevronBackCircleOutline
          className="back-btn"
          onClick={() => navigate(-1)}
          size={35}
        />
        <h5>Track Your Order</h5>
        <div>
          &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          &nbsp; &nbsp;&nbsp; &nbsp;
        </div>
      </div>

      {loading ? (
        <CustomerLazyLoader />
      ) : (
        <div className="main-content">
          <div>
            <div className="order_id">
              <p>Order ID : {orderDetails?.order_id}</p>
            </div>

            <div className="status-stepper">
              <OrderTracking statuses={statuses} />
            </div>
          </div>

          <div className="delivery-support-container">
            <div className="delivery-content">
              <div className="del-img-container">
                <img src={ShippingSvg} alt="" />
              </div>
              <div>
                <h6>Shipping</h6>
                <p>Free shipping for above 15,000 INR</p>
              </div>
            </div>
            <div className="delivery-content">
              <div className="del-img-container">
                <img src={PaymentSvg} alt="" />
              </div>
              <div>
                <h6>Flexible Payments</h6>
                <p>Multiple secure payment options</p>
              </div>
            </div>
            <div className="delivery-content">
              <div className="del-img-container">
                <img src={SupportSvg} alt="" />
              </div>
              <div>
                <h6>24/7 Support</h6>
                <p>We support online all days.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDeliveryStatus;
