import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./AddProducts.scss";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import ErrorMessage from "../../../../../assets/elements/ErrorMessage";
import PrimaryButton from "../../../../../assets/elements/PrimaryButton";
import { FormGroup, Input, Label } from "reactstrap";

import { IoArrowBackCircleSharp } from "react-icons/io5";
import MainServices from "../../../../../services/MainServices";

import FolderSVG from "../../../../../assets/images/img/folder.svg";
import {
  notifyError,
  notifySuccess,
} from "../../../../../assets/elements/Toaster";

import FormData from "form-data";
import { IoMdClose } from "react-icons/io";
import axios from "axios";

const addProductSchema = Yup.object().shape({
  name: Yup.string().required("Title is Required"),
  price: Yup.number().required("Price is required"),
  offer_price: Yup.number().required("Offer Price is required"),
  quantity: Yup.number().required("Quantity is required"),
  files: Yup.mixed().test(
    "fileList",
    "At least one image is required",
    (value) => {
      const currentImageArray = Object.entries(value);
      return currentImageArray.length > 0;
    }
  ),
  description: Yup.string().required("Description is Required"),
  featured_product: Yup.boolean().required("This field is required"),
  category: Yup.mixed().required("This is required field"),
});

const AddProducts = () => {
  const [currentImage, setCurrentImage] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const handleChooseImage = (e) => {
    const files = e.target.files;
    // console.log(e.target.files)
    setCurrentImage(files);
  };

  console.log(currentImage);

  const navigate = useNavigate();

  const { addProduct, getAllCategories } = MainServices();

  //Add product api
  const addProductItem = async (values) => {
    setAddLoading(true);

    let details = new FormData();

    console.log(values);

    details.append("name", values?.name);
    details.append("description", values?.description);
    details.append("price", values?.price);
    details.append("offer_price", values?.offer_price);
    details.append("quantity", values?.quantity);
    details.append("featured_product", values?.featured_product);
    details.append("banner_image", values?.banner_image);
    details.append("category", values?.category);
    details.append("files", values?.files);
    for (let i = 0; i < currentImage.length; ++i) {
      details.append("files", currentImage[i]);
    }

    console.log(details);
 

    await addProduct(details)
      .then((res) => {
        if (res) {
          let resData = res;
          let message = resData?.message;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            navigate("/admin/products");
            notifySuccess("Product Added Successfully");
            setAddLoading(false);
          } else {
            setAddLoading(false);
            notifyError(message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setAddLoading(false);
      });
  };

  const getCategoriesList = async () => {
    setProductLoading(true);
    const details = {};

    await axios
      .all([getAllCategories(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let total = resData?.data?.total;
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data?.data : [];
            //console.log(resData);
            setCategories(resData);
            setProductLoading(false);
          } else {
            setProductLoading(false);
          }
        }
        setProductLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in retreving categories");
      });
  };

  useEffect(() => {
    getCategoriesList();
  }, []);

  return (
    <div className="main-addproduct-container">
      <div className="back-button">
        <IoArrowBackCircleSharp
          className="back-icon"
          size={40}
          onClick={() => navigate(-1)}
        />
      </div>

      <div className="addproduct-form">
        <Formik
          initialValues={{
            name: "",
            price: "",
            offer_price: "",
            description: "",
            quantity: "",
            featured_product: false,
            files: currentImage,
            banner_image: "image",
            category: "",
          }}
          onSubmit={(values) => {
            addProductItem(values);
          }}
          validationSchema={addProductSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            touched,
            isValid,
          }) => (
            <Form>
              <FormGroup>
                <Label className="input-headings">Name of the Product: </Label>
                <Input
                  className="text-input add-product-input"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Name of the product"
                />
                <ErrorMessage errors={errors} touched={touched} name="name" />
              </FormGroup>

              <FormGroup>
                <Label className="input-headings">Category :</Label>
                <Input
                  type="select"
                  name="category"
                  className="text-input stock-select"
                  value={values.category}
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  {categories?.map((cat) => {
                    return (
                      <option key={cat._id} value={cat._id}>
                        {cat.categoryName}
                      </option>
                    );
                  })}
                </Input>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="in_stock"
                />
              </FormGroup>

              <FormGroup>
                <Label className="input-headings">Product's Price: </Label>
                <Input
                  className="text-input add-product-input-price"
                  name="price"
                  type="number"
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Price of the product"
                />
                <ErrorMessage errors={errors} touched={touched} name="price" />
              </FormGroup>

              <FormGroup>
                <Label className="input-headings">Offer Price: </Label>
                <Input
                  className="text-input add-product-input-price"
                  name="offer_price"
                  type="number"
                  value={values.offer_price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Offer of the product"
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="offer_price"
                />
              </FormGroup>

              <FormGroup>
                <Label className="input-headings">Quantity: </Label>
                <Input
                  className="text-input add-product-input-price"
                  name="quantity"
                  type="number"
                  value={values.quantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Quantity of the product"
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="quantity"
                />
              </FormGroup>

              <FormGroup>
                <Label className="input-headings">Featured Product:</Label>
                <Input
                  type="select"
                  name="featured_product"
                  className="text-input stock-select"
                  value={values.featured_product}
                  onChange={handleChange}
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Input>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="featured_product"
                />
              </FormGroup>

              <FormGroup>
                <Label className="input-headings">Product Description: </Label>{" "}
                <br />
                <textarea
                  className="text-input add-product-description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter description"
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="description"
                />
              </FormGroup>

              <FormGroup
                style={{ display: "flex", gap: "1rem", width: "50rem" }}
              >
                <div style={{ width: "35rem" }}>
                  <Label className="input-headings">Product's Image: </Label>
                  <div className="wrap-custom-file">
                    <input
                      id="file-upload"
                      type="file"
                      name="files"
                      accept="image/*"
                      multiple
                      max={5}
                      maxLength={5}
                      onChange={(e) => {
                        handleChooseImage(e);
                        setFieldValue("files", e.target.files || []);
                      }}
                      className="custom-drag-drop"
                    />

                    <label htmlFor="file-upload">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 0,
                        }}
                      >
                        <img
                          src={FolderSVG}
                          alt=""
                          style={{
                            objectFit: "contain",
                            height: "80%",
                            width: "80%",
                          }}
                        />

                        <p>Drag & Drop</p>
                        <button
                          style={{
                            background: "#005478",
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          +
                        </button>
                      </div>
                    </label>
                  </div>
                  <ErrorMessage
                    errors={errors}
                    touched={touched}
                    name="files"
                  />
                </div>

                <div className="selected-images-container">
                  {Object.entries(currentImage).map(([key, image], index) => (
                    <div key={index} className="selected-image">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Selected Product`}
                        style={{ objectFit: "contain" }}
                      />

                      <IoMdClose
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const updatedImages = { ...currentImage };
                          delete updatedImages[key];
                          setCurrentImage(updatedImages);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </FormGroup>

              <div className="login-btn">
                <PrimaryButton
                  style={{
                    width: "330px",
                    padding: ".650rem 0",
                    fontFamily: "Montserrat-Regular",
                    background: "#005478",
                    fontSize: "1rem",
                    borderRadius: "22px",
                  }}
                  type="submit"
                  title="Add Product"
                  isLoading={addLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddProducts;
