import React, { useState, useEffect } from "react";
import "./OrderDetails.scss";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom"; 
import axios from "axios";
import MainServices from "../../../../services/MainServices";
import { CustomerLazyLoader } from "../../../../assets/loaders";
import moment from "moment";

const OrderDetails = () => {
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const { getOrderOfUser } = MainServices();

  const getOrder = async () => {
    setLoading(true);
    try {
      const details = {};
      const res = await axios.all([getOrderOfUser(details)]);
      if (res[0]) {
        let resData = res[0].data.data ? res[0].data.data.order : [];
        setOrders(resData);
      }
    } catch (error) {
      console.error("An error occurred in retrieving Orders", error);
    } finally {
      setLoading(false);
    }
  };

   const formatDate = (dateString) => {
     return moment(dateString).format("DD MMMM YYYY");
   };

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrder();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="main-order-details-container">
      <div className="header">
        <IoChevronBackCircleOutline
          className="back-btn"
          onClick={() => navigate(-1)}
          size={35}
        />
        <h5>Order Details</h5>
        <div>
          &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          &nbsp; &nbsp;&nbsp; &nbsp;
        </div>
      </div>
      <div className="order-details-content">
        <div className="items-table">
          {loading ? (
            <CustomerLazyLoader />
          ) : orders && orders.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      borderTopLeftRadius: "14px",
                      borderBottomLeftRadius: "14px",
                      textAlign: "center",
                    }}
                  >
                    Product
                  </th>
                  <th style={{ textAlign: "center" }}>Order ID</th>
                  <th style={{ textAlign: "center" }}>Ordered On</th>
                  <th style={{ textAlign: "center" }}>Price</th>
                  <th style={{ textAlign: "center" }}>Quantity</th>
                  <th>Subtotal</th>
                  <th
                    style={{
                      borderTopRightRadius: "14px",
                      borderBottomRightRadius: "14px",
                      textAlign: "center",
                    }}
                  >
                    Track
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) =>
                  order.cart.map((item) => (
                    <tr key={item._id}>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate({
                            pathname: "/products/product-details",
                            search: `productId=${item?.product_id?._id}`,
                          });
                        }}
                      >
                        <div className="product-container">
                          <div className="product-image">
                            <img
                              src={item.product_id.images[0]}
                              alt="product-img"
                            />
                          </div>
                          <div>
                            <h6 style={{ textTransform:"capitalize"}}>{item.product_id.name}</h6>
                          </div>
                        </div>
                      </td>
                      <td style={{ textAlign: "center" }}>{order.order_id}</td>
                      <td style={{ textAlign: "center" }}>{formatDate(order.createdAt)}</td>
                      <td
                        style={{
                          fontFamily: "Montserrat-Medium",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        {item.product_id.offer_price?.toLocaleString()}
                      </td>
                      <td style={{ textAlign: "center" }}>{item.quantity}</td>
                      <td
                        style={{
                          fontFamily: "Montserrat-Medium",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        {item.total_price?.toLocaleString()}
                      </td>
                      <td
                        onClick={() =>
                          navigate("/track-order")
                        }
                        style={{
                          textAlign: "center",
                          fontFamily: "Montserrat-Medium",
                          cursor: "pointer",
                          color: "rgb(65, 133, 63)",
                          textDecoration: "underline",
                        }}
                      >
                        Track Order
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          ) : (
            <div className="no-products-found">
              <h6>
                No Orders Found! Browse and order your products Now!{" "}
                <span onClick={() => navigate("/categories")}>Click Here</span>
              </h6>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
