import React, { useState, useEffect, useCallback } from "react";
import "./Categories.scss"; 

//Tabs
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Product from "../../../../assets/elements/Customer/Product";
import Line from "../../../../assets/images/img/cus-line.svg";

import MainServices from "../../../../services/MainServices";
import { CustomerLazyLoader } from "../../../../assets/loaders";
import { deBounce } from "../../../../assets/functions/TimingFunctions";
import { GoSearch } from "react-icons/go";

const Categories = () => {
  const [categoryProducts, setCategoryProducts] = useState({
    All: [],
    Sofas: [],
    Chairs: [],
    Beds: [],
    Tables: [],
    DiningTables: [],
    Mattress: [],
    BeanBags: [],
    Wardrobes: [],
    TeaTables: [],
    Bedsheets: [],
    Curtains: [],
    Wallpapers: [],
  });

  const tabList = [
    "All",
    "Sofas",
    "Chairs",
    "Beds",
    "Tables",
    "DiningTables",
    "Mattress",
    "BeanBags",
    "Wardrobes",
    "TeaTables",
    "Bedsheets",
    "Curtains",
    "Wallpapers",
  ];

  const [cateogryLoading, setCategoryLoading] = useState(false);
  const [searchString, setSearchString] = useState("");

  const { getProducts } = MainServices();

  //get product list api
  const fetchCategoryProducts = async (category) => {
    setCategoryLoading(true);
    const details =
      category === "All"
        ? { productName: searchString }
        : { category, productName: searchString };

    try {
      const response = await getProducts(details);
      if (response.success) {
        const products = response.data?.data || [];
        setCategoryProducts((prev) => ({
          ...prev,
          [category]: products,
        }));
        setCategoryLoading(false);
      } else {
        setCategoryProducts((prev) => ({
          ...prev,
          [category]: [],
        }));
      }
    } catch (error) {
      console.error(
        `An error occurred in retrieving ${category} Products`,
        error
      );
      setCategoryProducts((prev) => ({
        ...prev,
        [category]: [],
      }));
    } finally {
      setCategoryLoading(false);
    }
  };

  const handleTabSelect = (category) => { 
    if (categoryProducts[category].length === 0) {
      fetchCategoryProducts(category);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    deBounce((value) => {
      setSearchString(value);
    }, 1000),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    fetchCategoryProducts("All", searchString);
    // eslint-disable-next-line
  }, [searchString]);

  return (
    <div className="main-best-seller">
      <div className="best-seller-headings">
        <div className="search-heading">
          <h6>
            <img src={Line} alt="" />
            Categories
          </h6>
          <div className="search-container">
            <GoSearch className="search-icon" size={22} />
            <input
              type="text"
              placeholder="Search Product name..."
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <div className="tabs-products">
          <Tabs onSelect={(index) => handleTabSelect(tabList[index])}>
           {/*  <TabList>
              {tabList.map((category) => (
                <Tab key={category}>{category}</Tab>
              ))}
            </TabList> */}

            {cateogryLoading ? (
              <CustomerLazyLoader />
            ) : (
              <>
                {tabList.map((category) => (
                  <TabPanel key={category}>
                    <div className="product-container">
                      {categoryProducts[category].length === 0 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <h4 style={{ textAlign: "center" }}>
                            Sorry! we don't have this product currently..
                          </h4>
                        </div>
                      ) : (
                        <>
                          {categoryProducts[category].map((product) => (
                            <Product product={product} key={product.id} />
                          ))}
                        </>
                      )}
                    </div>
                  </TabPanel>
                ))}
              </>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Categories;
