import React, { useEffect } from "react";
import "./Footer.scss";

import LogoIcon from "../../../assets/images/img/logo.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  return (
    <>
      <div className="main-footer">
        <div className="footer-content">
          <div className="logo">
            <img src={LogoIcon} alt="" />
            <h4>
              Hind<span style={{ color: "#F6F791" }}>wala</span>.com
            </h4>
          </div>
        </div>
        <div className="footer-div">
          <div className="footer-content">
            <h6>Company</h6>
            <ul>
              <li onClick={() => navigate("/about")}>About Us</li>
              <li onClick={() => navigate("/contact")}>Contact Us</li>
            </ul>
          </div>
          <div className="footer-content">
            <h6>Customer Services</h6>
            <ul> 
              <li onClick={() => navigate("/order-details")}>
                Track your order
              </li>
              <li>Return</li>
              <li>FAQ</li>
            </ul>
          </div>
          <div className="footer-content">
            <h6>Our Information</h6>
            <ul>
              <li onClick={() => navigate("/privacy-policy")}>
                Privacy Policy
              </li>
              <li onClick={() => navigate("/payment-policy")}>
                Payment Policy
              </li>
            </ul>
          </div>
          <div className="footer-content">
            <h6>Contact Info</h6>
            <ul>
              <li>+91 9849022026</li>
              <li>furniture987@gmail.com</li>
              <li style={{ maxWidth: "280px" }}>
                10-53/1, Pothireddy Pally, <br /> Near Rural Police Station{" "}
                <br /> Sangareddy - 502001, Telangana.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>Copyright @ 2024 Furniture. All Rights Reserved.</p>
      </div>
    </>
  );
};

export default Footer;
