import React, { useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import "./Pagination.scss";

export default function CustomPagination({
  currentPage,
  rowsPerPage,
  totalCount,
  onPageChange,
  onRowsPerPage,
}) { 
  const [selectedRows, setSelectedRows] = useState(rowsPerPage);
  const options = [
    { label: "10", value: 10 },
    ...(totalCount > 20 ? [{ label: "20", value: 20 }] : []),
    ...(totalCount > 50 ? [{ label: "50", value: 50 }] : []),
  ];

  let page = +currentPage;
  let limit = Math.ceil(totalCount / rowsPerPage);

  let count = page * rowsPerPage < totalCount ? rowsPerPage * page : totalCount;

  const onPage = (number) => {
    onPageChange && onPageChange(number);
  };

  /*  const onPrevious = () => {
    if (pageList > 1) {
      setPageList(pageList - 1);
      if (page > 1) {
        onPageChange && onPageChange(page - 1);
      }
    }
  }; */

  /*  const onNextPage = () => {
    if (pageList + 5 <= limit) {
      setPageList(pageList + 1);
      if (page < limit) {
        onPageChange && onPageChange(page + 1);
      }
    }
  }; */

  const onRowsChange = (e) => {
    setSelectedRows(e.target.value);
    onRowsPerPage && onRowsPerPage(e.target.value);
  };

  const onFisrt = () => {
    onPageChange && onPageChange(1);
  
  };
  const onLast = () => {
    onPageChange && onPageChange(limit);
  
  };

  return (
    <div className="balloons-pagination d-flex justify-content-space-between">
      <div className="d-flex rows-per-page gap-2">
        <div className="rows-select">
          <select
            className="form-control form-select"
            id="fv-topics"
            name="status"
            data-placeholder="Row per Page"
            required
            value={selectedRows}
            onChange={onRowsChange}
          >
            {options.map((each) => (
              <option key={each.value} value={each.value}>
                {each.label}
              </option>
            ))}
          </select>
        </div>
        <div className="rows-info mt-1 mx-2">
          <span className="mx-1">Showing</span>
          <span className="mx-1 font-weight-bold">{count}</span>
          <span className="mx-1">of</span>
          <span className="mx-1 font-weight-bold">{totalCount}</span>
        </div>
      </div>

      <Pagination className="pagination-main">
        <PaginationItem onClick={onFisrt}>
          <PaginationLink first />
        </PaginationItem>
        {[...Array(limit)].map((_, index) => (
          <PaginationItem
            key={index}
            className={`page-item ${page === index + 1 ? "active" : ""}`}
            onClick={() => onPage(index + 1)}
          >
            <PaginationLink>{index + 1}</PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem onClick={onLast}>
          <PaginationLink last />
        </PaginationItem>
      </Pagination>
    </div>
  );
}
