import React, { useState, useEffect } from "react";
import "./AddressChange.scss";
import { IoChevronBackCircleOutline } from "react-icons/io5"; 
import ErrorMessage from "../../../../assets/elements/ErrorMessage";
import { ButtonLoader, CustomerLazyLoader } from "../../../../assets/loaders";
import { Field, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import MainServices from "../../../../services/MainServices";
import * as Yup from "yup";
import { notifySuccess, notifyWarn } from "../../../../assets/elements/Toaster";

const billDetailsSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  phone_number: Yup.number().required("Phone Number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  pin_code: Yup.mixed().required("Pin Code is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  address: Yup.string().required("Address is required"),
});

const AddressChange = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const oldAddress = location.state;

  const [pincodes, setPincodes] = useState([]);
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  console.log("oldAddress", oldAddress);

  const { getAllPincodes, updateAddressOfUser } = MainServices();

  //get pincode api
  const getPincodesList = async () => {
    setPincodeLoading(true);
    const details = {};

    await axios
      .all([getAllPincodes(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0]; 
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data?.data : [];
            //console.log(resData);
            setPincodes(resData);

            setPincodeLoading(false);
          } else {
            setPincodeLoading(false);
          }
        }
        setPincodeLoading(false);
      })
      .catch(function (res) {
        console.log(res);

        if (res.message) console.log("An error occurred in retreving pincodes");
      });
  };

  //update address api
  const updateAddress = async (values) => {
    setButtonLoading(true);

    const details = {
      addressId: oldAddress?._id,
      first_name: values.first_name,
      last_name: values.last_name, 
      email: values.email,
      mobile_number: values.phone_number,
      city: values.city,
      pin_code: values.pin_code,
      state: values.state,
      street_address: values.address,
    };
    console.log(details);

    try {
      const res = await axios.all([updateAddressOfUser(details)]);
      if (res[0]) {
        console.log(res[0].success);
        if (res[0].success) {
          navigate(-1);
         notifySuccess("Successfully Updated Your Address!");
        } else {
          notifyWarn("Error while Updating Address");
        }
      }
    } catch (error) {
      console.error("Error while updating address", error);
    } finally {
      setButtonLoading(false);
    }
  };

  const handlePincodeChange = (e, setFieldValue) => {
    const selectedPincodeId = e.target.value;
    const selectedPincodeData = pincodes.find(
      (pin) => pin._id === selectedPincodeId
    );
    setFieldValue("pin_code", selectedPincodeData);
  };

  useEffect(() => {
    getPincodesList();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="main-billing-container">
      <div className="billing-heading">
        <IoChevronBackCircleOutline
          className="back-btn"
          onClick={() => navigate(-1)}
          size={35}
        />
        <h5>Change Your Delivery Address</h5>
      </div>
      {pincodeLoading ? (
        <CustomerLazyLoader />
      ) : (
        <div className="billing-details-form">
          <div className="contact-form">
            <Formik
              initialValues={{
                first_name: oldAddress?.first_name || "",
                last_name: oldAddress?.last_name || "",
                phone_number: oldAddress?.mobile_number || "",
                email: oldAddress?.email || "",
                pin_code: oldAddress?.pin_code || {},
                city: oldAddress?.city || "",
                state: oldAddress?.state || "",
                address: oldAddress?.street_address || "",
              }}
              validationSchema={billDetailsSchema}
              onSubmit={(values) => {
                const submissionData = {
                  ...values,
                  pin_code: values.pin_code._id,
                };
                updateAddress(submissionData);
              }}
            >
              {({
                values,
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
                touched,
                isValid,
              }) => (
                <Form>
                  <div className="main-div">
                    <div className="all-fields">
                      <div className="input-container-name">
                        <div>
                          <label htmlFor="first_name">First Name*</label> <br />
                          <Field
                            type="text"
                            name="first_name"
                            placeholder="Enter your First name..."
                            style={{ width: "200px" }}
                          />
                          <br />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="first_name"
                          />
                        </div>
                        <div>
                          <label htmlFor="last_name">Last Name*</label> <br />
                          <Field
                            type="text"
                            name="last_name"
                            placeholder="Enter your Last name..."
                            style={{ width: "200px" }}
                          />
                          <br />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="last_name"
                          />
                        </div>
                      </div>
                      <div className="input-container">
                        <label htmlFor="phone_number">Phone*</label> <br />
                        <Field
                          type="number"
                          name="phone_number"
                          placeholder="+91"
                        />
                        <br />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="phone_number"
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="email">Email*</label> <br />
                        <Field
                          type="email"
                          name="email"
                          placeholder="example@gmail.com"
                        />
                        <br />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="email"
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="pin_code">Select Pin Code*</label>{" "}
                        <br />
                        <Field
                          as="select"
                          name="pin_code"
                          value={values.pin_code._id || ""}
                          onChange={(e) =>
                            handlePincodeChange(e, setFieldValue)
                          }
                        >
                          {pincodes.map((pin) => {
                            return (
                              <option key={pin._id} value={pin._id}>
                                {pin.pincode}
                              </option>
                            );
                          })}
                        </Field>
                        <br />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="pin_code"
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="city">City*</label> <br />
                        <Field as="select" name="city">
                          <option value="">Select your city</option>
                          <option value="Hyderabad">Hyderabad</option>
                          <option value="Sangareddy">Sangareddy</option>
                        </Field>
                        <br />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="city"
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="state">State*</label> <br />
                        <Field as="select" name="state">
                          <option value="">Select your state</option>
                          <option value="Telangana">Telangana</option>
                        </Field>
                        <br />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="state"
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="address">Street Address*</label> <br />
                        <Field
                          as="textarea"
                          name="address"
                          placeholder="Enter your Address..."
                        />
                        <br />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="address"
                        />
                      </div>
                      <button type="submit" className="proceed-to-checkout-btn">
                        {buttonLoading ? <ButtonLoader /> : "Update Address"}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressChange;
