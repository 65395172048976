import React, { useEffect, useState } from "react";
import "./CartBillingDetails.scss";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Formik, Field } from "formik";
import ErrorMessage from "../../../../../assets/elements/ErrorMessage";
import * as Yup from "yup";
import MainServices from "../../../../../services/MainServices";
import axios from "axios";
import {
  ButtonLoader,
  CustomerLazyLoader,
} from "../../../../../assets/loaders";
import { CartState } from "../../../../../context/CustomerCart";
import {
  notifySuccess,
  notifyWarn,
} from "../../../../../assets/elements/Toaster";
import useCustomerAuth from "../../../../../hooks/useCustomerAuth";

const billDetailsSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  phone_number: Yup.number().required("Phone Number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  pin_code: Yup.string().required("Pin Code is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  address: Yup.string().required("Address is required"),
});

const oldDetailsSchema = Yup.object().shape({
  selected_address: Yup.boolean().required("Please check this input!"),
});

const CartBillingDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [pincodes, setPincodes] = useState([]);
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const [userAddress, setUserAddress] = useState([]);
  const [userAddressToggle, setUserAddressToggle] = useState([]);

  const { user } = useCustomerAuth();

  const {
    getAllPincodes,
    placeOrder,
    getUser,
    getAddressOfUser,
    confirmOrder,
    getPaymentStatus,
  } = MainServices();

  /*  console.log("user: ", user) */

  useEffect(() => {
    window.scrollTo(0, 0);
    getPincodesList();
    getCustomerInfo();
    getAddress();
    // eslint-disable-next-line
  }, []);

  const {
    state: { cart },
  } = CartState();

  const handlePincodeChange = (e, setFieldValue) => {
    const selectedPincode = e.target.value;
    setFieldValue("pin_code", selectedPincode);
  };

  //get pincode api
  const getPincodesList = async () => {
    setPincodeLoading(true);
    const details = {};

    await axios
      .all([getAllPincodes(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data?.data : [];
            //console.log(resData);
            setPincodes(resData);

            setPincodeLoading(false);
          } else {
            setPincodeLoading(false);
          }
        }
        setPincodeLoading(false);
      })
      .catch(function (res) {
        console.log(res);

        if (res.message) console.log("An error occurred in retreving pincodes");
      });
  };

  const orderConfirm = async () => {
    const details = {};

    try {
      const res = await axios.all([confirmOrder(details)]);
      if (res[0]?.success) {
        return res[0].data?.data || [];
      } else {
        throw new Error("Order confirmation failed");
      }
    } catch (error) {
      console.log("An error occurred in confirm order payment", error);
      throw error;
    }
  };

  const openPaymentLink = (shortUrl) => {
    window.open(shortUrl, "_blank");
  };

  const placeOrderAfterPayment = async (values) => {
    const details = {
      cart: cart.map((c) => c._id),
      first_name: values.first_name,
      last_name: values.last_name,
      address: values.address,
      email: values.email,
      mobile_number: values.phone_number,
      city: values.city,
      pin_code: values.pin_code,
      state: values.state,
      street_address: values.address,
    };

    const res = await axios.all([placeOrder(details)]);
    if (res[0]?.success) {
      notifySuccess("Successfully Placed Your Order!");
    } else {
      notifyWarn("Error while placing Order");
    }
  };

  const placeOrderOfProduct = async (values) => {
    setPlaceOrderLoading(true);
    try {
      const confirmOrderData = await orderConfirm();
      openPaymentLink(confirmOrderData.short_url);
      console.log(confirmOrderData);

      await placeOrderAfterPayment(values)

      //const paymentStatus = await checkPaymentStatus(confirmOrderData.id);


    /*   if (paymentStatus === "paid") {
        await placeOrderAfterPayment(values);
      } else {
        console.log(paymentStatus);
        notifyWarn("Payment failed. Order not placed.");
      } */
    } catch (error) {
      console.error("Error while placing order", error);
      notifyWarn("Error while placing order");
    } finally {
      setPlaceOrderLoading(false);
    }
  };

  const checkPaymentStatus = async (paymentId) => {
    const details = {
      payment_id: paymentId,
    };
    console.log(details);
    await axios
      .all([getPaymentStatus(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data?.data : "";
          } else {
            setPincodeLoading(false);
          }
        }
      })
      .catch(function (res) {
        console.log(res);

        if (res.message)
          console.log("An error occurred in retreving Payment status");
      });
  };

  //get user
  const getCustomerInfo = async (values) => {
    setPincodeLoading(true);
    const details = {};
    await axios
      .all([getUser(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data?.data : "";
            //console.log(resData);
            setUserAddressToggle(resData);

            setPincodeLoading(false);
          } else {
            setPincodeLoading(false);
          }
        }
        setPincodeLoading(false);
      })
      .catch(function (res) {
        console.log(res);

        if (res.message) console.log("An error occurred in retreving pincodes");
      });
  };
  //get address of user
  const getAddress = async () => {
    setPincodeLoading(true);
    const details = {
      //user: userAddressToggle._id,
    };
    console.log(details);
    await axios
      .all([getAddressOfUser(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data?.data : "";
            //console.log(resData);
            setUserAddress(resData);

            setPincodeLoading(false);
          } else {
            setPincodeLoading(false);
          }
        }
        setPincodeLoading(false);
      })
      .catch(function (res) {
        console.log(res);

        if (res.message) console.log("An error occurred in retreving Address");
      });
  };

  return (
    <div className="main-billing-container">
      <div className="billing-heading">
        <IoChevronBackCircleOutline
          className="back-btn"
          onClick={() => navigate("/")}
          size={35}
        />
        <h5>Billing Details</h5>
      </div>

      {pincodeLoading ? (
        <>
          <CustomerLazyLoader />
        </>
      ) : (
        <div className="billing-details-form">
          <div className="contact-form">
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                phone_number: "",
                email: user?.email || "",
                pin_code: "",
                city: "",
                state: "",
                address: "",
                selected_address: false,
              }}
              validationSchema={
                userAddressToggle?.is_address
                  ? oldDetailsSchema
                  : billDetailsSchema
              }
              onSubmit={(values) => console.log(values)}
            >
              {({
                values,
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
                touched,
                isValid,
              }) => (
                <Form>
                  <div className="main-div">
                    {userAddressToggle?.is_address ? (
                      <div className="select-address-container">
                        <div className="address-header">
                          <input
                            type="checkbox"
                            id="old_address"
                            name="selected_address"
                            className="check-cus-address"
                            checked
                            readOnly
                          />

                          <h5>Select your previous Address</h5>
                        </div>
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="selected_address"
                        />
                        <div className="old-address-details">
                          <div className="address-detail">
                            <label
                              htmlFor="firs_name"
                              className="label-heading"
                            >
                              First Name :
                            </label>
                            <h6>{userAddress?.first_name}</h6>
                          </div>
                          <div className="address-detail">
                            <label
                              htmlFor="last_name"
                              className="label-heading"
                            >
                              Last Name :
                            </label>
                            <h6>{userAddress?.last_name}</h6>
                          </div>
                          <div className="address-detail">
                            <label htmlFor="email" className="label-heading">
                              Email :
                            </label>
                            <h6> {userAddress?.email}</h6>
                          </div>
                          <div className="address-detail">
                            <label htmlFor="pin-code" className="label-heading">
                              Pin Code :
                            </label>
                            <h6> {userAddress?.pin_code?.pincode}</h6>
                          </div>
                          <div className="address-detail">
                            <label htmlFor="city" className="label-heading">
                              City :
                            </label>
                            <h6> {userAddress?.city}</h6>
                          </div>
                          <div className="address-detail">
                            <label htmlFor="state" className="label-heading">
                              State :
                            </label>
                            <h6> {userAddress?.state}</h6>
                          </div>
                          <div className="address-detail">
                            <label
                              htmlFor="mobile_number"
                              className="label-heading"
                            >
                              Mobile Number :
                            </label>
                            <h6> {userAddress?.mobile_number}</h6>
                          </div>
                          <div className="address-detail">
                            <label
                              htmlFor="street_address"
                              className="label-heading"
                            >
                              Street Address :
                            </label>
                            <h6> {userAddress?.street_address}</h6>
                          </div>
                        </div>
                        <h6 className="change-adddress-text">
                          Do you want to change Address? {"   "}
                          <span
                            onClick={() =>
                              navigate("/change-address", {
                                state: userAddress,
                              })
                            }
                            style={{
                              color: "#00770c",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Click Here!
                          </span>
                        </h6>
                      </div>
                    ) : (
                      <>
                        <div className="all-fields">
                          <div className="input-container-name">
                            <div>
                              <label htmlFor="first_name">First Name*</label>{" "}
                              <br />
                              <Field
                                type="text"
                                name="first_name"
                                placeholder="Enter your First name..."
                                style={{ width: "200px" }}
                              />
                              <br />
                              <ErrorMessage
                                errors={errors}
                                touched={touched}
                                name="first_name"
                              />
                            </div>
                            <div>
                              <label htmlFor="last_name">Last Name*</label>{" "}
                              <br />
                              <Field
                                type="text"
                                name="last_name"
                                placeholder="Enter your Last name..."
                                style={{ width: "200px" }}
                              />
                              <br />
                              <ErrorMessage
                                errors={errors}
                                touched={touched}
                                name="last_name"
                              />
                            </div>
                          </div>
                          <div className="input-container">
                            <label htmlFor="phone_number">Phone*</label> <br />
                            <Field
                              type="number"
                              name="phone_number"
                              placeholder="+91"
                            />
                            <br />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="phone_number"
                            />
                          </div>
                          <div className="input-container">
                            <label htmlFor="email">Email*</label> <br />
                            <Field
                              type="email"
                              name="email"
                              placeholder="example@gmail.com"
                            />
                            <br />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="email"
                            />
                          </div>
                          <div className="input-container">
                            <label htmlFor="pin_code">Pin Code*</label> <br />
                            <Field
                              as="select"
                              name="pin_code"
                              onChange={(e) =>
                                handlePincodeChange(e, setFieldValue)
                              }
                            >
                              <option value="">Select PinCode</option>
                              {pincodes.map((pin) => {
                                return (
                                  <option key={pin._id} value={pin._id}>
                                    {pin?.pincode}
                                  </option>
                                );
                              })}
                            </Field>
                            <br />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="pin_code"
                            />
                          </div>
                          <div className="input-container">
                            <label htmlFor="city">City*</label> <br />
                            <Field as="select" name="city">
                              <option value="">Select your city</option>
                              <option value="Hyderabad">Hyderabad</option>
                              <option value="Sangareddy">Sangareddy</option>
                            </Field>
                            <br />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="city"
                            />
                          </div>
                          <div className="input-container">
                            <label htmlFor="state">State*</label> <br />
                            <Field as="select" name="state">
                              <option value="">Select your state</option>
                              <option value="Telangana">Telangana</option>
                            </Field>
                            <br />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="state"
                            />
                          </div>
                          <div className="input-container">
                            <label htmlFor="address">Street Address*</label>{" "}
                            <br />
                            <Field
                              as="textarea"
                              name="address"
                              placeholder="Enter your Address..."
                            />
                            <br />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="address"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="order-summary">
                      <h5 className="summary-heading">Order Summary</h5>

                      <div className="summary-items">
                        <p>Items</p>
                        <h5>{state?.cart?.length}</h5>
                      </div>
                      <div className="summary-items">
                        <p>Sub-Total</p>
                        <h5>{state.cartAmount.toLocaleString()}</h5>
                      </div>
                      <div
                        className="summary-items"
                        style={{
                          borderBottom: "1px solid #BABABA",
                          paddingBottom: "2rem",
                        }}
                      >
                        <p>Shipping</p>
                        <h5 style={{ color: "#00770c", fontSize: "16px" }}>
                          FREE
                        </h5>
                      </div>
                      {/*   <div
                        className="summary-items"
                        style={{
                          borderBottom: "1px solid #BABABA",
                          paddingBottom: "2rem",
                        }}
                      >
                        <p>Taxes</p>
                        <h5>18%</h5>
                      </div> */}
                      <div
                        className="summary-items"
                        style={{ paddingTop: "2rem" }}
                      >
                        <p>Total</p>
                        <h5>{state.cartAmount.toLocaleString()}</h5>
                      </div>
                      <button type="submit" className="proceed-to-checkout-btn">
                        {placeOrderLoading ? (
                          <ButtonLoader />
                        ) : (
                          "Proceed Payment"
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartBillingDetails;
