import React, { useState, useEffect } from "react";
import "./Product.scss";

import CartIcon from "../../images/img/cart-cus.svg"; 
import { useNavigate } from "react-router-dom";

import * as Storage from "../../../store/LocalStorage";
import useCustomerConfig from "../../../hooks/useCutomerConfig";
import { CartState } from "../../../context/CustomerCart";

import { ButtonLoader } from "../../loaders";
import { notifySuccess, notifyWarn } from "../Toaster";
import MainServices from "../../../services/MainServices";
import axios from "axios";
import useCustomerAuth from "../../../hooks/useCustomerAuth";

const Product = ({ product }) => {
  const config = useCustomerConfig();
  const navigate = useNavigate();
  const { user } = useCustomerAuth();
  //console.log(user);

  const [buttonLoading, setButtonLoading] = useState(false);

  const {
    state: { cart },
    dispatch,
  } = CartState();

  //console.log("cart from state: ", cart);

  const { addItemToCart } = MainServices();

  useEffect(() => {
    Storage.set(config.customerCartItems, JSON.stringify(cart));
  }, [cart, config.customerCartItems]);

  //add to cart api
  const handleAddToCart = async () => {
    setButtonLoading(true);

    const details = {
      product_id: product?._id,
      quantity: 1,
    };

    try {
      const res = await axios.all([addItemToCart(details)]);
      if (res[0]) {
        console.log(res[0].success);
        if (res[0].success) {
          const cartItem = res[0].data.data;
          dispatch({
            type: "ADD_TO_CART",
            payload: cartItem,
          });
          const updatedCart = [...cart, { ...product, qty: 1 }];
          Storage.set(config.customerCartItems, JSON.stringify(updatedCart));

          notifySuccess(`${product?.name} added to the cart.`);
          
        } else {
          notifyWarn("Item Already in Cart");
        }
      }
    } catch (error) {
      console.error("Error adding to cart", error);
    } finally {
      setButtonLoading(false);
    }
  };
 

  return (
    <div style={{ display: "flex", flexDirection: "column" }} key={product._id}>
      <div
        className="product-card"
        key={product._id}
        onClick={() => {
          navigate({
            pathname: "/products/product-details",
            search: `productId=${product?._id}`,
          });
        }}
      >
        <div className="image-container">
          <div className="offer-cart">
            <span className="offer">{product?.discount}% OFF</span>
            <div className="cart">
              <img src={CartIcon} alt="cart" />
            </div>
          </div>
          <div className="product-img-container">
            <img
              src={product?.images[0]}
              className="product-img"
              alt="product"
            />
          </div>
        </div>
        <div className="product-info">
          <div className="rate-review">
            <h4>{product.name}</h4>
            {/*  <p>
              <img src={StarReview} alt="star" /> {product.rating}
            </p> */}
          </div>
          <p className="product-price">
            Offer Price - {product.offer_price?.toLocaleString()}
          </p>
          <p className="product-description">
            Price - {product.price?.toLocaleString()}/
          </p>
        </div>
      </div>
      <div className="cta-buttons-div">
        {cart?.some((p) => p.product_id?._id === product?._id) ? (
          <div>
            <h6 style={{ textAlign: "left" }}> Product in Cart!</h6>
          </div>
        ) : (
          <button className="add-to-cart" onClick={() => handleAddToCart()}>
            {buttonLoading ? <ButtonLoader /> : "Add To Cart"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Product;
