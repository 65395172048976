export const cartReducer = (state, action) => {
  switch (action.type) {
    case "SET_CART":
      return {
        ...state,
        cart: action.payload.map(item => {
          const result = {
            ...item,
            quantity: item.quantity,
          };
          //delete result["quantity"];

          return result;
        })
      }
    case "ADD_TO_CART":  return {
        ...state,
        cart: [...state.cart, { ...action.payload, quantity: 1 }],
 
      };  
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter((c) => c._id !== action.payload._id),
      };
    case "CHANGE_CART_QTY":
      return {
        ...state,
        cart: state.cart.map((c) =>
          c._id === action.payload._id
            ? { ...c, quantity: action.payload.quantity }
            : c
        ),
      };
    default:
      return state;
  }
};
