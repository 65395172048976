import React from "react";
import "./Sidebar.scss";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";

import lineSvg from "../../../assets/images/img/line.svg";

import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import CrownSvg from "../../../assets/images/img/crown-svg.svg";
import sidebarSofa from "../../../assets/images/img/sidebar-sofa.svg";

import useAuth from "../../../hooks/useAuth";
import * as Storage from "../../../store/LocalStorage";
import useConfig from "../../../hooks/useConfig";

import DashboardSVG from "../../../assets/images/img/dashboard.svg";
import ProductsSVG from "../../../assets/images/img/productSVG.svg";
import OrdersSVG from "../../../assets/images/img/ordersSVG.svg";
import UsersSVG from "../../../assets/images/img/userSVG.svg";
import SettingsSVG from "../../../assets/images/img/settingsSVG.svg";
import LogoutSVG from "../../../assets/images/img/logourSVG.svg";
import PinCodeSVG from "../../../assets/images/img/pin-code.svg";

export default function Sidebar() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const config = useConfig();

  const onLogout = () => {
    logout();
    Storage.remove(config.sessionKey);
    console.log("terminal Cleared");
    navigate("/admin");
  };

  return (
    <SimpleBar className="sidebar-main" autoHide={false}>
      <div className="sidebar-logo">
        <img src={CrownSvg} className="crown-img" alt="logo" />
        <h3 className="logo-text">
          Hind<span style={{ color: "hsla(122, 60%, 21%, 0.95)" }}>wala</span>
          .com
        </h3>
      </div>
      <div className="sidebar-menu-list">
        <div className={`each-icon-div`}>
          <RouterLink
            id="dashboard"
            className="each-icon"
            to={"/admin/dashboard"}
          >
            <img className="dashboard-svg" src={DashboardSVG} alt="" />
            Dashboard
          </RouterLink>
        </div>
        <div className="each-icon-div">
          <RouterLink
            id="categories"
            className="each-icon"
            to="/admin/categories"
          >
            <img className="svg-icon" src={ProductsSVG} alt="categories" />
            Categories
          </RouterLink>
        </div>

        <div className="each-icon-div">
          <RouterLink id="orders" className="each-icon" to="/admin/orders">
            <img className="svg-icon" src={OrdersSVG} alt="orders" />
            Order's
          </RouterLink>
        </div>
        <div className="each-icon-div">
          <RouterLink id="products" className="each-icon" to="/admin/products">
            <img className="svg-icon" src={ProductsSVG} alt="products" />
            Product's
          </RouterLink>
        </div>

        <div className="each-icon-div">
          <RouterLink id="users" className="each-icon" to="/admin/users">
            <img className="svg-icon" src={UsersSVG} alt="orders" />
            User's
          </RouterLink>
        </div>

        <div className="each-icon-div">
          <RouterLink
            id="pin-codes"
            className="each-icon"
            to="/admin/pin-codes"
          >
            <img className="svg-icon" src={PinCodeSVG} alt="pin-codes" />
            Pin Code's
          </RouterLink>
        </div>
        <div className="each-icon-div">
          <RouterLink id="settings" className="each-icon" to="/admin/settings">
            <img className="svg-icon" src={SettingsSVG} alt="settings" />
            Settings
          </RouterLink>
        </div>

        <div style={{ marginTop: "5rem" }} className="each-icon-div">
          <img style={{ paddingLeft: "3rem" }} src={lineSvg} alt="" />
        </div>

        <div className="each-icon-div">
          <div
            id="logout"
            style={{ cursor: "pointer" }}
            className="each-icon"
            onClick={() => {
              onLogout();
            }}
          >
            <img className="svg-icon" src={LogoutSVG} alt="logout" />
            Logout
          </div>
        </div>
      </div>
    </SimpleBar>
  );
}
