import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.scss";

import ChartPie from "../../../../assets/images/img/chart-pie.svg";
import Revenue from "../../../../assets/images/img/revenue.svg";
import UsersPie from "../../../../assets/images/img/users-box.svg"; 

import MainServices from "../../../../services/MainServices";
import { LazyLoader } from "../../../../assets/loaders";

import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  Title
);

//Line Chart
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      left: "0",
      fontSize: "16px",
    },
    title: {
      display: false,
      text: "Total Sales",
      position: "bottom",

      style: {
        fontSize: "29px",
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        borderDash: [10, 5],
      },
    },
  },
};

const Dashboard = () => {
  const navigate = useNavigate();

  const [productStats, setProductStats] = useState([]);
  const [statsLoading, setStatsLoading] = useState(false);

  const [usersCount, setUsersCount] = useState();
  const [orderCount, setOrderCount] = useState();
  const [salesAmount, setSalesAmount] = useState();

  const [orderStatus, setOrderStatus] = useState([]);
  const [salesByYear, setSalesByYear] = useState([]);
  const [salesByMonth, setSalesByMonth] = useState([]);

  const [selectedOption, setSelectedOption] = useState("monthly"); 

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
    const dummySalesByMonth = [
      { totalSales: 12000, month: "Jan", year: 2023 },
      { totalSales: 13000, month: "Feb", year: 2023 },
      { totalSales: 14500, month: "Mar", year: 2023 },
      { totalSales: 14000, month: "Apr", year: 2023 },
      // Add more months as needed
    ];

  const {
    getProductsStats,
    getUsersCount,
    getAllOrder,
    getSalesAmount,
    getStatsOfAllOrderStatus,
    getSalesStatsByMonth,
    getSalesStatsByYear,
  } = MainServices();

  //get Product stats api
  const getProductCount = async () => {
    setStatsLoading(true);
    const details = {};

    await axios
      .all([getProductsStats(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data?.data : [];
            //  console.log(resData);
            setProductStats(resData);
            setStatsLoading(false);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in retreving Product counts");
      });
  };

  const getCustomerCount = async () => {
    setStatsLoading(true);
    const details = {};

    await axios
      .all([getUsersCount(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            //console.log(resData);
            setUsersCount(resData?.count);
            setStatsLoading(false);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in retreving Product counts");
      });
  };

  const getOrdersCount = async () => {
    setStatsLoading(true);

    await axios
      .all([getAllOrder()])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0]; 
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data?.total : [];
            setOrderCount(resData);

            setStatsLoading(false);
          } else {
            setStatsLoading(false);
          }
        }
        setStatsLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in retreving Orders");
      });
  };

  const getSalesAmountCount = async () => {
    setStatsLoading(true);

    await axios
      .all([getSalesAmount()])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0]; 
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data?.data : [];
            setSalesAmount(resData);

            setStatsLoading(false);
          } else {
            setStatsLoading(false);
          }
        }
        setStatsLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in retreving Amount");
      });
  };

  const getOrderStatus = async () => {
    setStatsLoading(true);

    await axios
      .all([getStatsOfAllOrderStatus()])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0]; 
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data.data : [];
            setOrderStatus(resData);

            setStatsLoading(false);
          } else {
            setStatsLoading(false);
          }
        }
        setStatsLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in retreving stats of status");
      });
  };

  const getSalesYearly = async () => {
    setStatsLoading(true);

    await axios
      .all([getSalesStatsByYear()])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0]; 
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data.data : [];
            setSalesByYear(resData);

            setStatsLoading(false);
          } else {
            setStatsLoading(false);
          }
        }
        setStatsLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in retreving stats of status");
      });
  };

  const getSalesMonthly = async () => {
    setStatsLoading(true);

    await axios
      .all([getSalesStatsByMonth()])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0]; 
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data.data : [];
            setSalesByMonth(resData);

            setStatsLoading(false);
          } else {
            setStatsLoading(false);
          }
        }
        setStatsLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in retreving stats of status");
      });
  };

  console.log(orderStatus);

  useEffect(() => {
    getProductCount();
    getCustomerCount();
    getOrdersCount();
    getSalesAmountCount();
    getOrderStatus();
    getSalesYearly();
    getSalesMonthly();

    // eslint-disable-next-line
  }, []);

  const statusColorMap = {
    Inprogress: "#F7BD89",
    "Out For Delivery": "#d2ff00",
    Completed: "green",
    Rejected: "#F74545",
    Delivered: "#00ff34",
  };

  const borderColorMap = {
    Inprogress: "#F7BD89",
    "Out For Delivery": "#d2ff00",
    Completed: "green",
    Rejected: "#F74545",
    Delivered: "#00ff34",
  };

  const doughnutData = {
    labels: orderStatus.map((status) => status.status),
    datasets: [
      {
        label: "Number of Orders",
        data: orderStatus.map((status) => status.count),
        backgroundColor: orderStatus.map((item) => statusColorMap[item.status]),
        borderColor: orderStatus.map((item) => borderColorMap[item.status]),
        borderWidth: 1,
        hoverBorderWidth: 8,
      },
    ],
  };

  const doughnutOptions = {
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  const yearlyData = {
    labels: salesByYear.map((sales) => sales.year),
    datasets: [
      {
        label: "Yearly Sales",
        data: salesByYear.map((sales) => sales.totalSales),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const monthlyData = {
    labels: dummySalesByMonth.map((sales) => sales.month + " " + sales.year),
    datasets: [
      {
        label: "Monthly Sale",
        data: dummySalesByMonth.map((sales) => sales.totalSales),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const chartData = selectedOption === "monthly" ? monthlyData : yearlyData;

  return (
    <div className="main-dashboard">
      {statsLoading ? (
        <LazyLoader />
      ) : (
        <div className="boxes-container">
          <div className="boxes">
            <div className="left-content">
              <h5>Active Sales</h5>

              <h3>₹{salesAmount?.toLocaleString()}</h3>

              <h6>Total Revenue</h6>
            </div>
            <div className="right-content">
              <img src={ChartPie} alt="img" />
            </div>
          </div>
          <div className="boxes" onClick={() => navigate("/admin/orders")}>
            <div className="left-content">
              <h5>Orders</h5>

              <h3>{orderCount}</h3>

              <h6>All Orders</h6>
            </div>
            <div className="right-content">
              <img src={Revenue} alt="img" />
            </div>
          </div>
          <div className="boxes" onClick={() => navigate("/admin/products")}>
            <div className="left-content">
              <h5>Products</h5>

              <h3>{productStats?.count} </h3>

              <h6>Active Products</h6>
            </div>
            <div className="right-content">
              <img src={Revenue} alt="img" />
            </div>
          </div>
          <div className="boxes" onClick={() => navigate("/admin/users")}>
            <div className="left-content">
              <h5>Users</h5>

              <h3>{usersCount}</h3>

              <h6>Active users</h6>
            </div>
            <div className="right-content">
              <img src={UsersPie} alt="img" />
            </div>
          </div>
        </div>
      )}

      <div className="charts-container">
        {salesAmount === 0 ? (
          <></>
        ) : (
          <div className="line-chart">
            <div style={{ display: "flex" }} className="select-box">
              <h5>Total Sales</h5>
              <select
                id="chartOption"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="monthly">
                  {/* <img src={calenderSVG} alt="" /> */} Monthly
                </option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
            <Line data={chartData} options={options} />
          </div>
        )}

        {orderCount === 0 ? (
          <></>
        ) : (
          <>
            <div className="pie-chart">
              <div style={{ display: "flex" }} className="select-box">
                <h5>Total Orders</h5>
              </div>
              <div style={{ marginTop: "3rem" }}>
                <Doughnut data={doughnutData} options={doughnutOptions} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
