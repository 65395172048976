export const set = (key, value) => localStorage.setItem(key, value);
export const get = (key) => localStorage.getItem(key);
export const remove = (key) => localStorage.removeItem(key);
export const removeCartItem = (key, item) => {
  const data = JSON.parse(localStorage.getItem("customerCartItems"));
  console.log("data", data)

  if (data) {
    const updatedData = data?.filter((cartItem) => cartItem._id !== item._id);
    console.log(updatedData)
    set("customerCartItems", JSON.stringify(updatedData));
  }
};
export const clear = () => localStorage.clear();
