import React, { useState, useEffect, useCallback } from "react";
import "./CategoryBasedProducts.scss";

import Product from "../../../../assets/elements/Customer/Product";
import Line from "../../../../assets/images/img/cus-line.svg";
import { useLocation } from "react-router-dom";

import MainServices from "../../../../services/MainServices";
import { CustomerLazyLoader } from "../../../../assets/loaders";
import { deBounce } from "../../../../assets/functions/TimingFunctions";
import { GoSearch } from "react-icons/go";

const CategoryBasedProducts = () => {
  const location = useLocation();
  const cat = location.state;

  const [categoryProducts, setCategoryProducts] = useState({});

  const [cateogryLoading, setCategoryLoading] = useState(false);
  const [searchString, setSearchString] = useState("");

  const { getProducts } = MainServices();

  //get product list api
  const fetchCategoryProducts = async (category) => {
    setCategoryLoading(true);
    const details = {  category,  productName: searchString };

    try {
      const response = await getProducts(details);
      if (response.success) {
        const products = response.data?.data || [];
        setCategoryProducts(products);
        setCategoryLoading(false);
      } else {
        setCategoryProducts([]);
      }
    } catch (error) {
      console.error(
        `An error occurred in retrieving ${category} Products`,
        error
      );
      setCategoryProducts([]);
    } finally {
      setCategoryLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    deBounce((value) => {
      setSearchString(value);
    }, 1000),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    fetchCategoryProducts(cat.categoryName, searchString);
    // eslint-disable-next-line
  }, [searchString]);

  console.log("Products based on Category: ", categoryProducts);

  return (
    <div className="main-best-seller">
      <div className="best-seller-headings">
        <div className="search-heading">
          <h6 style={{ textTransform: "capitalize" }}>
            <img src={Line} alt="" />
            {cat.categoryName} Product's
          </h6>
          <div className="search-container">
            <GoSearch className="search-icon" size={22} />
            <input
              type="text"
              placeholder="Search Product name..."
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {cateogryLoading ? (
          <CustomerLazyLoader />
        ) : (
          <div className="product-container">
            {categoryProducts && categoryProducts.length > 0 ? (
              <>
                {categoryProducts.map((product) => (
                  <Product product={product} key={product._id} />
                ))}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h6 style={{ textAlign: "center", color: "#000" }}>
                  Sorry! We don't have any products for  {cat.categoryName} category.
                </h6>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryBasedProducts;
