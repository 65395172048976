import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AddNewCategories.scss";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import ErrorMessage from "../../../../../assets/elements/ErrorMessage";
import PrimaryButton from "../../../../../assets/elements/PrimaryButton";
import { FormGroup, Input, Label } from "reactstrap";

import { IoArrowBackCircleSharp } from "react-icons/io5";
import MainServices from "../../../../../services/MainServices";

import {
  notifyError,
  notifySuccess,
} from "../../../../../assets/elements/Toaster";

import { IoMdClose } from "react-icons/io";
import FormData from "form-data";
import FolderSVG from "../../../../../assets/images/img/folder.svg";

const addCategorySchema = Yup.object().shape({
  categoryName: Yup.string().required("Category is Required"),
  description: Yup.string().required("Description is Required"),
  file: Yup.mixed().test(
    "fileList",
    "Only one image is required",
    (value) => {
      return value && value.length === 1;
    }
  ),
});

const AddNewCategories = () => {
  const [addLoading, setAddLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState([]);

  const navigate = useNavigate();

  const { addCategory } = MainServices();
 
  const addCategoryItem = async (values) => {
    setAddLoading(true);

    let category = new FormData();
    category.append("categoryName", values.categoryName);
    category.append("description", values.description);
    category.append("file", values.file[0])
 
    /* for (let i = 0; i < currentImage.length; i++) {
      category.append("file", currentImage[i]);
    } */

    console.log("category", category);

    await addCategory(category)
      .then((res) => {
        if (res) {
          let resData = res;
          let message = resData?.message;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            navigate("/admin/categories");
            notifySuccess("Category Added Successfully");
          } else {
            notifyError(message);
          }
        }
        setAddLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAddLoading(false);
      });
  };

  // Image functions
  const handleChooseImage = (e) => {
    const file = Array.from(e.target.files);
    setCurrentImage(file);
  };

  return (
    <div className="main-addpincode-container">
      <div className="back-button">
        <IoArrowBackCircleSharp
          className="back-icon"
          size={40}
          onClick={() => navigate(-1)}
        />
      </div>

      <div className="addproduct-form">
        <Formik
          initialValues={{
            categoryName: "",
            description: "",
            file: currentImage,
          }}
          onSubmit={(values) => {
            addCategoryItem(values);
          }}
          validationSchema={addCategorySchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            touched,
          }) => (
            <Form>
              <FormGroup>
                <Label className="input-headings">Category: </Label>
                <Input
                  className="text-input add-product-input"
                  name="categoryName"
                  type="input"
                  value={values.categoryName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Table, Spices..."
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="categoryName"
                />
              </FormGroup>

              <FormGroup>
                <Label className="input-headings">Description: </Label>
                <Input
                  className="text-input add-product-input"
                  name="description"
                  type="input"
                  value={values.description}
                  onChange={handleChange}
                  placeholder="Enter description"
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="description"
                />
              </FormGroup>

              <FormGroup
                style={{ display: "flex", gap: "1rem", width: "50rem" }}
              >
                <div style={{ width: "20rem" }}>
                  <Label className="input-headings">Category Image: </Label>{" "}
                
                  <br />
                  <div className="wrap-custom-file">
                    <input
                      id="file-upload"
                      type="file"
                      name="file"
                      accept="image/*"
                      multiple
                      onChange={(e) => {
                        handleChooseImage(e);
                        setFieldValue("file", e.target.files);
                      }}
                      className="custom-drag-drop"
                    />
                    <label htmlFor="file-upload">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 0,
                        }}
                      >
                        <img
                          src={FolderSVG}
                          alt=""
                          style={{
                            objectFit: "contain",
                            height: "80%",
                            width: "80%",
                          }}
                        />
                        <p>Drag & Drop</p>
                        <button
                          style={{
                            background: "#005478",
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          +
                        </button>
                      </div>
                    </label>
                  </div>
                  <ErrorMessage errors={errors} touched={touched} name="file" />
                </div>

                <div
                  className="selected-images-container"
                  style={{ left: "25rem" }}
                >
                  {currentImage.length > 0 &&
                    currentImage.map((image, index) => (
                      <div key={index} className="selected-image">
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Selected Product`}
                          style={{ objectFit: "contain" }}
                        />
                        <IoMdClose
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const updatedImages = currentImage.filter(
                              (_, i) => i !== index
                            );
                            setCurrentImage(updatedImages);
                            setFieldValue("file", updatedImages);
                          }}
                        />
                      </div>
                    ))}
                </div>
              </FormGroup>

              <div className="login-btn">
                <PrimaryButton
                  style={{
                    width: "330px",
                    padding: ".650rem 0",
                    fontFamily: "Montserrat-Regular",
                    background: "#005478",
                    fontSize: "1rem",
                    borderRadius: "22px",
                    marginTop: "1rem",
                  }}
                  type="submit"
                  title="Add Category"
                  isLoading={addLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddNewCategories;
