import React, { useState, useEffect, useCallback } from "react";
import "./Orders.scss";

import { GoSearch } from "react-icons/go";

import editicon from "../../../../assets/images/img/edit-curved.svg";
import MainServices from "../../../../services/MainServices";
import axios from "axios";
import { ButtonLoader, LazyLoader } from "../../../../assets/loaders";
import { TbEye } from "react-icons/tb";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom"; 
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import * as Yup from "yup";
import { Formik } from "formik";
import ErrorMessage from "../../../../assets/elements/ErrorMessage";
import { notifySuccess, notifyWarn } from "../../../../assets/elements/Toaster";
import CustomPagination from "../../../../assets/pagination/Pagination";
import { deBounce } from "../../../../assets/functions/TimingFunctions";

import {
  filtersToUrlParams, 
} from "../../../../assets/functions/filters";

const updateStatusSchema = Yup.object().shape({
  status: Yup.mixed().required("Status is Required"),
  password: Yup.string().required("password is required"),
});

const Orders = () => {
  const location = useLocation();

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  const [ordersLoading, setOrdersLoading] = useState(false);
  const [ordersList, setOrdersList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [statusOfOrder, setStatusOfOrder] = useState("");
  console.log("statusoforder", statusOfOrder);

  const [searchString, setSearchString] = useState("");

  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);

  const [type, setType] = useState("password");

  const [statusList] = useState([
    { label: "Out For Delivery", value: "Out For Delivery" },
    { label: "Inprogress", value: "Inprogress" },
    { label: "Delivered", value: "Delivered" },
    { label: "Completed", value: "Completed" },
    { label: "Rejected", value: "Rejected" },
  ]);

  //modal functions
  const [editModal, setEditModal] = useState(false);
  const [selectedEditStatus, setSelectedEditStatus] = useState([]);

  const toggleEditModal = (row) => {
    setEditModal(!editModal);
    setSelectedEditStatus(row);
    console.log(row);
  };

  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate({
      pathname: `/admin/orders/order-details`,
      search: `orderId=${id}`,
    });
  };

  const actions = (row) => {
    if (!row) return;
    return (
      <div className="action-btn-main">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(row._id)}
        >
          <TbEye data-tooltip-id="view-tool-tip" size={20} color="#005478" />
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            toggleEditModal(row);
          }}
        >
          <img
            data-tooltip-id="edit-tool-tip"
            src={editicon}
            alt="edit"
            className="action-icon"
          />
        </div>
      </div>
    );
  };

  const onChangeType = () => {
    setType(type === "password" ? "text" : "password");
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("DD MMMM YYYY");
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Out For Delivery":
        return "status-on-delivery";
      case "Delivered":
        return "status-delivered";
      case "Completed":
        return "status-completed";
      case "Inprogress":
        return "status-pending";
      case "Rejected":
        return "status-removed";
      default:
        return "default-status";
    }
  };

  //Pagination
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });

  //pagination functions
  const onPageChange = (page) => {
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
    });
    navigate({ search });
  };

  const onRowsChange = (rows) => {
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
    });
    navigate({
      search,
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    deBounce((value) => {
      setSearchString(value);
    }, 1000),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const { getAllOrder, updateOrderStatus } = MainServices();

  //get Orders api
  const getOrders = async ({ page, limit, orderId = "", status }) => {
    setOrdersLoading(true);

    const details = {
      page: page,
      limit: limit,
      orderId: orderId,
      status: statusOfOrder,
    };

    await axios
      .all([getAllOrder(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let total = resData?.data?.total;
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data?.data : [];
            //console.log(resData);
            setOrdersList(resData);
            setTotalCount(total);
            setOrdersLoading(false);
          } else {
            setOrdersLoading(false);
          }
        }
        setOrdersLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in retreving Orders");
      });
  };

  //update order Status
  const updateOrderByStatus = async (values) => {
    setUpdateStatusLoading(true);
    const details = {
      orderId: selectedEditStatus?._id,
      status: values.status,
      password: values.password,
    };

    console.log(details);
    try {
      const res = await axios.all([updateOrderStatus(details)]);
      if (res[0]) {
        let message = res[0].message;
        if (res[0].success) {
          notifySuccess("Successfully Updated Order Status");
          getOrders({ page: pagination.page, limit: pagination.limit });
          setUpdateStatusLoading(false);
          toggleEditModal();
        } else {
          notifyWarn(message);
          setUpdateStatusLoading(false);
          toggleEditModal();
        }
      }
    } catch (error) {
      console.error("Error updating status", error);
    } finally {
      setUpdateStatusLoading(false);
    }
  };

  useEffect(() => {
    let pagination = {
      limit: changedParams?.limit || 10,
      page: changedParams?.page || 1,
    };
    setPagination(pagination);
    getOrders({
      page: pagination.page,
      limit: pagination.limit,
      orderId: searchString,
    });
    // eslint-disable-next-line
  }, [changedParams.limit, changedParams.page, searchString, statusOfOrder]);

  return (
    <div className="main-orders-container">
      <div className="filter">
        <div className="search-container">
          <GoSearch className="search-icon" size={22} />
          <input
            type="text"
            placeholder="Search by OrderID"
            onChange={handleSearchChange}
          />
        </div>

        <div className="select-box">
          <select
            id="chartOption"
            onChange={(e) => setStatusOfOrder(e.target.value)}
          >
            <option label="ALL" value=""></option>
            {statusList.map((each) => (
              <option value={each.value} key={each.value}>
                {each.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {ordersLoading ? (
        <LazyLoader />
      ) : (
        <div className="orders-table">
          {ordersList && ordersList.length > 0 ? (
            <>
              <table>
                <thead>
                  <tr>
                    <th>CUSTOMER </th>

                    <th>ORDER ID</th>
                    <th>ORDER DATE</th>
                    {/* <th>PRODUCT NAME</th>
                    <th style={{ textAlign: "center" }}>QUANTITY</th> */}
                    <th style={{ textAlign: "center" }}>AMOUNT</th>
                    <th style={{ textAlign: "center" }}>STATUS</th>
                    <th style={{ textAlign: "center" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersList?.map((item) => (
                    <tr key={item._id}>
                      <td
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          gap: ".600rem",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                          }}
                          src={item.user?.profile_picture}
                          alt=""
                        />
                        {item.user?.name}
                      </td>

                      <td>{item?.order_id}</td>
                      <td>{formatDate(item?.createdAt)}</td>
                      {/*  <td>
                        {item.cart?.map((product, index) => (
                          <span key={product.product_id._id}>
                            {product.product_id.name}
                            {index < item.cart.length - 1 && ", "}
                          </span>
                        ))}
                      </td>
                      <td style={{ textAlign: "center" }}>
                    
                        {item.cart?.map((product, index) => (
                          <span key={product.quantity}>
                            {product.quantity}
                            {index < item.cart.length - 1 && ", "}
                          </span>
                        ))}
                      </td> */}
                      <td style={{ textAlign: "center" }}>
                        {item.total_cart_amount.toLocaleString()}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <div className={getStatusClass(item.status)}>
                          <p
                            style={{
                              marginBottom: "0",
                              fontFamily: "Montserrat-Medium",
                            }}
                          >
                            {item.status}
                          </p>
                        </div>
                      </td>
                      <td style={{ textAlign: "center" }}>{actions(item)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div className="no-products-div">
              <h5 style={{ fontFamily: "Montserrat-Medium" }}>
                No Orders found!
              </h5>
            </div>
          )}
          {ordersList && ordersList.length > 0 ? (
            <>
              <CustomPagination
                currentPage={pagination.page}
                rowsPerPage={pagination.limit}
                totalCount={totalCount}
                onPageChange={onPageChange}
                onRowsPerPage={onRowsChange}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      )}

      <ReactTooltip
        id="view-tool-tip"
        place="bottom"
        variant="info"
        content="Order details"
      />
      <ReactTooltip
        id="edit-tool-tip"
        place="bottom"
        variant="info"
        content="Edit Order Status"
      />
      <Modal isOpen={editModal} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Edit Order Status</ModalHeader>
        <ModalBody>
          <div className="main-modal-div">
            <div className="delete-modal-container">
              <img src={editicon} alt="" />
              <h6>
                Update Order Status{" "}
                <span className="edit-prod-name">
                  {selectedEditStatus?.order_id}
                </span>
              </h6>
            </div>
            <Formik
              initialValues={{
                status: selectedEditStatus?.status || selectedStatus,
                password: "",
              }}
              validationSchema={updateStatusSchema}
              onSubmit={(values) => {
                updateOrderByStatus(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <div>
                  <div className="status-container">
                    <label className="input-headings" htmlFor="fv-topics">
                      Status:
                    </label>
                    <div className="form-control-wrap ">
                      <select
                        className="form-control form-select"
                        id="fv-topics"
                        name="status"
                        data-placeholder="Select a option"
                        required
                        onChange={(e) => {
                          setSelectedStatus(e.target.value);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={values.status}
                      >
                        <option label="Select Status" value=""></option>
                        {statusList.map((each) => (
                          <option value={each.value} key={each.value}>
                            {each.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {values.status && (
                    <>
                      <div className="status-container">
                        <label className="input-headings" htmlFor="password">
                          Password:
                        </label>
                        <div className="form-control-wrap">
                          <input
                            className="form-control"
                            id="password"
                            name="password"
                            type={type}
                            placeholder="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <div
                            className="eye-status-icon"
                            onClick={onChangeType}
                          >
                            {type === "password" ? (
                              <AiOutlineEye size={22} />
                            ) : (
                              <AiOutlineEyeInvisible size={22} />
                            )}
                          </div>
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="password"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-12 mt-2 ">
                    <span className="nk-content p-0">
                      <button
                        className="btn btn-primary"
                        style={{ backgroundColor: "#005478" }}
                        onClick={handleSubmit}
                        type="submit"
                      >
                        <span>
                          {updateStatusLoading ? <ButtonLoader /> : "Update"}
                        </span>
                      </button>
                    </span>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            style={{ width: "120px" }}
            onClick={toggleEditModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Orders;
