import React, { useState, useEffect, useCallback } from "react";
import "./Categories.scss";
import { LazyLoader } from "../../../../assets/loaders";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";

import { filtersToUrlParams } from "../../../../assets/functions/filters";

import { useLocation, useNavigate } from "react-router-dom";

import { GoSearch } from "react-icons/go";
import categoriesVG from "../../../../assets/images/img/product-white.svg";
import axios from "axios";
import MainServices from "../../../../services/MainServices";
import deleteicon from "../../../../assets/images/img/delete-curved.svg";
import CustomPagination from "../../../../assets/pagination/Pagination";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../../assets/elements/Toaster";

import { ButtonLoader } from "../../../../assets/loaders";
import { deBounce } from "../../../../assets/functions/TimingFunctions";

const Categories = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [categories, setCategories] = useState([]);

  const [productLoading, setProductLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedDeleteProduct, setSelectedDeleteProduct] = useState([]);

  const [searchString, setSearchString] = useState("");

  //Pagination
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  const [totalCount, setTotalCount] = useState(0);

  //pagination functions
  const onPageChange = (page) => {
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
    });
    navigate({ search });
  };

  const onRowsChange = (rows) => {
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
    });
    navigate({
      search,
    });
  };

  //modal functions
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = (row) => {
    setDeleteModal(!deleteModal);
    setSelectedDeleteProduct(row);
  };

  const { deleteCategory, getAllCategories } = MainServices();

  useEffect(() => {
    let pagination = {
      limit: changedParams?.limit || 10,
      page: changedParams?.page || 1,
    };
    setPagination(pagination);
    getCategoriesList({
      page: pagination.page,
      limit: pagination.limit,
      categoryName: searchString,
    });
    // eslint-disable-next-line
  }, [changedParams.limit, changedParams.page, searchString]);

  //get category api
  const getCategoriesList = async ({ page, limit, categoryName }) => {
    setProductLoading(true);
    const details = {
      page: page,
      limit: limit,
      categoryName: "",
    };
    console.log(details);

    await axios
      .all([getAllCategories(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let total = resData?.data?.total;
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data?.data : [];
            //console.log(resData);
            setCategories(resData);
            setTotalCount(total);
            setProductLoading(false);
          } else {
            setProductLoading(false);
          }
        }
        setProductLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in retreving categories");
      });
  };

  const deleteProduct = async (values) => {
    setDeleteLoading(true);

    const details = {
      categoryId: values?._id,
    };

    await axios
      .all([deleteCategory(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            notifySuccess("Categories Deleted Successfully");
            toggleDeleteModal();
            getCategoriesList({
              page: pagination.page,
              limit: pagination.limit,
            });
            setDeleteLoading(false);
          } else {
            if (message) notifyWarn(message);
            setDeleteLoading(false);
          }
        } else {
          setDeleteLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        notifyError(res.message);
        setDeleteLoading(false);
        if (res.message)
          console.log("An error occurred while Deleting Product");
      });

    setProductLoading(false);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("DD MMMM YYYY");
  };

  const actions = (row) => {
    if (!row) return;
    return (
      <div className="action-btn-main">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            toggleDeleteModal(row);
          }}
        >
          <img
            data-tooltip-id="delete-tool-tip"
            src={deleteicon}
            alt="delete"
            className="action-icon"
          />
        </div>
      </div>
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    deBounce((value) => {
      setSearchString(value);
    }, 1000),

    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  return (
    <div className="main-product">
      <div className="product-header">
        <div className="search-container">
          <GoSearch className="search-icon" size={22} />
          <input
            type="text"
            placeholder="Search Categories"
            onChange={handleSearchChange}
          />
        </div>

        <div className="add-product">
          <button onClick={() => navigate("/admin/categories/add-category")}>
            <img src={categoriesVG} alt="" />
            Add New Categories
          </button>
        </div>
      </div>
      {productLoading ? (
        <>
          <LazyLoader />
        </>
      ) : (
        <div className="product-table">
          {categories && categories.length > 0 ? (
            <>
              {" "}
              <table border={1}>
                <thead>
                  <tr>
                    <th> Category</th>
                    <th>Category Name</th>
                    <th className="td-text-center">Description</th>

                    <th className="td-text-center">Created At </th>
                    <th className="td-text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((item, index) => (
                    <tr key={item?._id}> 
                      <td
                        style={{ textTransform: "capitalize" }}
                        className="td-text-center"
                      >
                        <div
                          className="product-img-cont"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={item.image}
                            alt=""
                            className="product-image"
                          />
                        </div>
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {" "}
                        {item?.categoryName}
                      </td>
                      <td
                        className="td-text-center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.description}
                      </td>
                      <td className="td-text-center">
                        {formatDate(item?.createdAt)}
                      </td>
                      <td className="td-text-center">{actions(item)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div className="no-products-div">
              <h5 style={{ fontFamily: "Montserrat-Medium" }}>
                No categories found!
              </h5>
            </div>
          )}
        </div>
      )}
      {categories && categories.length > 0 ? (
        <>
          <CustomPagination
            currentPage={pagination.page}
            rowsPerPage={pagination.limit}
            totalCount={totalCount}
            onPageChange={onPageChange}
            onRowsPerPage={onRowsChange}
          />
        </>
      ) : (
        <></>
      )}

      <ReactTooltip
        id="view-tool-tip"
        place="bottom"
        variant="info"
        content="Product details"
      />
      <ReactTooltip
        id="edit-tool-tip"
        place="bottom"
        variant="info"
        content="Edit Product"
      />
      <ReactTooltip id="delete-tool-tip" place="bottom" content="Delete" />
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Delete PinCode</ModalHeader>
        <ModalBody>
          <div className="delete-modal-container">
            <img src={deleteicon} alt="" />
            <h6>
              Are you sure you want to delete this Category{" "}
              <span className="del-prod-name">
                {selectedDeleteProduct?.categoryName}
              </span>
              ?
            </h6>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            style={{ width: "120px" }}
            onClick={() => deleteProduct(selectedDeleteProduct)}
          >
            {deleteLoading ? <ButtonLoader /> : "Delete"}
          </Button>

          <Button
            color="secondary"
            style={{ width: "120px" }}
            onClick={toggleDeleteModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Categories;
