import React, { useState, useEffect } from "react";
import "./OrderDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import MainServices from "../../../../../services/MainServices";
import axios from "axios";
import { LazyLoader } from "../../../../../assets/loaders";
import moment from "moment";
import { IoArrowBackCircleOutline } from "react-icons/io5";

const OrderDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const order_id = new URLSearchParams(location.search).get("orderId");

  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState([]);
  const [productOrder, setProductOrder] = useState([]);

  const { getAdminOrderById } = MainServices();

  const formatDate = (dateString) => {
    return moment(dateString).format("DD MMMM YYYY");
  };

  //get Prodcut by id api
  const getOrder = async (order_id) => {
    setLoading(true);
    const details = {
      orderId: order_id,
    };

    await axios
      .all([getAdminOrderById(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];

          if (resData.success) {
            resData = resData.data ? resData?.data.data : [];
            // console.log(resData);
            setOrder(resData);
            setProductOrder(resData.order.cart);
            setLoading(false);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        setLoading(false);
        if (res.message) console.log("An error occurred in retreving Order");
      });
  };

  console.log(productOrder);

  useEffect(() => {
    getOrder(order_id);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="order-details-head-div">
      <div className="back-button">
        <IoArrowBackCircleOutline
          className="back-icon"
          color="#000"
          stroke="#000"
          size={40}
          onClick={() => navigate(-1)}
        />
      </div>
      {loading ? (
        <LazyLoader />
      ) : (
        <>
          <div className="order-product-table">
            {productOrder && productOrder.length > 0 ? (
              <>
                {" "}
                <h5>Product Detials: </h5>
                <table border={1}>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th className="td-text-center">Quantity</th>
                      <th className="td-text-center">Price</th>
                      <th className="td-text-center">Stock Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productOrder?.map((item) => (
                      <tr key={item?._id}>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: ".45rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate({
                              pathname: `/admin/products/product-details`,
                              search: `productId=${item?.product_id?._id}`,
                            });
                          }}
                        >
                          <div className="product-img-cont">
                            <img
                              src={item?.product_id?.images[0]}
                              alt=""
                              className="product-image"
                            />
                          </div>
                          <p
                            style={{
                              marginBottom: "0",
                              textTransform: "capitalize",
                              fontWeight: "600",
                            }}
                          >
                            {item?.product_id?.name}
                          </p>
                        </td>
                        <td className="td-text-center">{item?.quantity}</td>
                        <td className="td-text-center">
                          {(item?.total_price).toLocaleString()}
                        </td>

                        <td className="td-text-center">
                          {item?.product_id?.quantity}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <div className="no-products-order-div">
                <h5 style={{ fontFamily: "Montserrat-Medium" }}>
                  Can't load product details
                </h5>
              </div>
            )}
          </div>
          <div className="main-order-details-container">
            <div className="order-info">
              <h5 className="customer-info">Order Details :</h5>
              <div className="info-data">
                <span>Status :</span>
                <h6> {order.order?.status}</h6>
              </div>
              <div className="info-data">
                <span>Order Id :</span>
                <h6>{order.order?.order_id}</h6>
              </div>
              <div className="info-data">
                <span>Total Amount :</span>
                <h6>{order.order?.total_cart_amount?.toLocaleString()}</h6>
              </div>
              <div className="info-data">
                <span>Order Date :</span>
                <h6>{formatDate(order.order?.createdAt)}</h6>
              </div>
              <h5 className="customer-info">Customer Address :</h5>

              <div className="info-data">
                <span>First Name :</span>
                <h6>{order.userAddress?.first_name}</h6>
              </div>
              <div className="info-data">
                <span>Last Name :</span>
                <h6>{order.userAddress?.last_name}</h6>
              </div>

              <div className="info-data">
                <span>Phone Number :</span>
                <h6 style={{ color: "#005478" }}>
                  {order.userAddress?.mobile_number}
                </h6>
              </div>

              <div className="info-data">
                <span>Email :</span>
                <h6
                  style={{
                    color: "#005478",
                    textDecoration: "none",
                    textTransform: "lowercase",
                  }}
                >
                  {order.userAddress?.email}
                </h6>
              </div>
              <div className="info-data">
                <span>City :</span>
                <h6>{order.userAddress?.city}</h6>
              </div>
              <div className="info-data">
                <span>Pin Code :</span>
                <h6>{order.userAddress?.pin_code?.pincode}</h6>
              </div>
              <div className="info-data">
                <span>State :</span>
                <h6>{order.userAddress?.state}</h6>
              </div>
              <div className="info-data">
                <span>Street Address :</span>
                <h6>{order.userAddress?.street_address}</h6>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderDetails;
