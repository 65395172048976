import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AddPinCode.scss";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import ErrorMessage from "../../../../../assets/elements/ErrorMessage";
import PrimaryButton from "../../../../../assets/elements/PrimaryButton";
import { FormGroup, Input, Label } from "reactstrap";

import { IoArrowBackCircleSharp } from "react-icons/io5";
import MainServices from "../../../../../services/MainServices";

import {
  notifyError,
  notifySuccess,
} from "../../../../../assets/elements/Toaster";

const addProductSchema = Yup.object().shape({
  pincode: Yup.number().required("Pincode is Required"),
});

const AddPinCode = () => {
  const [addLoading, setAddLoading] = useState(false);

  const navigate = useNavigate();

  const { addPincode } = MainServices();

  //Add product api
  const addProductItem = async (values) => {
    setAddLoading(true);

    let details = {
      pincode: values?.pincode,
    };

    console.log(values);

    await addPincode(details)
      .then((res) => {
        if (res) {
          let resData = res;
          let message = resData?.message;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            navigate("/admin/pin-codes");
            notifySuccess("PinCode Added Successfully");
            setAddLoading(false);
          } else {
            setAddLoading(false);
            notifyError(message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setAddLoading(false);
      });
  };

  return (
    <div className="main-addpincode-container">
      <div className="back-button">
        <IoArrowBackCircleSharp
          className="back-icon"
          size={40}
          onClick={() => navigate(-1)}
        />
      </div>

      <div className="addproduct-form">
        <Formik
          initialValues={{
            pincode: "",
            delivery_charges: "",
          }}
          onSubmit={(values) => {
            addProductItem(values);
          }}
          validationSchema={addProductSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            touched,
            isValid,
          }) => (
            <Form>
              <FormGroup>
                <Label className="input-headings">Pin Code: </Label>
                <Input
                  className="text-input add-product-input"
                  name="pincode"
                  type="number"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Ex: 502001"
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="pincode"
                />
              </FormGroup>

              <div className="login-btn">
                <PrimaryButton
                  style={{
                    width: "330px",
                    padding: ".650rem 0",
                    fontFamily: "Montserrat-Regular",
                    background: "#005478",
                    fontSize: "1rem",
                    borderRadius: "22px",
                    marginTop: "1rem",
                  }}
                  type="submit"
                  title="Add PinCode"
                  isLoading={addLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddPinCode;
