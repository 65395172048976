import React,{ useEffect } from "react";
import "./OrderComplete.scss";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";

const OrderComplete = () => {
  const navigate = useNavigate();

   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div className="main-order-complete">
      <IoChevronBackCircleOutline
        className="back-btn"
        onClick={() => navigate("/")}
        size={35}
      />

      <div className="order-details-container">
        <div className="top-section">
          <div className="order-complete">
            <FaCheck size={33} color="#fff" />
          </div>
          <h5>Your Order is completed </h5>
          <p>Thank you. Your Order has been received.</p>
        </div>

        <div className="order-detail-table">
          <div>
            <h5>Order ID</h5>
            <p>#bjgYFFYGJUG</p>
          </div>
          <div>
            <h5>Payment Method</h5>
            <p>#Phone pay</p>
          </div>
          <div>
            <h5>Transaction ID</h5>
            <p>TR56347GHY</p>
          </div>
          <div style={{ borderRight: "none" }}>
            <h5>Estimated Delivery Date</h5>
            <p>12 June 2024</p>
          </div>
        </div>

        <div className="buttons-container">
         
          <button
            className="button-1"
            onClick={() => navigate("/order-details")}
          >
            Order Details
          </button> 
        </div>
      </div>
    </div>
  );
};

export default OrderComplete;
