import React, { useState, useEffect, useCallback } from "react";
import "./Products.scss";
import { LazyLoader } from "../../../../assets/loaders";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { filtersToUrlParams } from "../../../../assets/functions/filters";

import { useLocation, useNavigate } from "react-router-dom";

import { GoSearch } from "react-icons/go";
import ProductSVG from "../../../../assets/images/img/product-white.svg";
import editicon from "../../../../assets/images/img/edit-curved.svg";
import axios from "axios";
import MainServices from "../../../../services/MainServices";
import deleteicon from "../../../../assets/images/img/delete-curved.svg";
import { TbEye } from "react-icons/tb";
import CustomPagination from "../../../../assets/pagination/Pagination";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../../assets/elements/Toaster";

import { ButtonLoader } from "../../../../assets/loaders";
import { deBounce } from "../../../../assets/functions/TimingFunctions";

const Products = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [products, setProducts] = useState([]);

  const [productLoading, setProductLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedDeleteProduct, setSelectedDeleteProduct] = useState([]);

  const [searchString, setSearchString] = useState("");

  //Pagination
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  const [totalCount, setTotalCount] = useState(0);

  //pagination functions
  const onPageChange = (page) => {
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
    });
    navigate({ search });
  };

  const onRowsChange = (rows) => {
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
    });
    navigate({
      search,
    });
  };

  //modal functions
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = (row) => {
    setDeleteModal(!deleteModal);
    setSelectedDeleteProduct(row);
  };

  const { deleteProductById, getAdminProduct } = MainServices();

  useEffect(() => {
    let pagination = {
      limit: changedParams?.limit || 10,
      page: changedParams?.page || 1,
    };
    setPagination(pagination);
    getProductsList({
      page: pagination.page,
      limit: pagination.limit,
      productName: searchString,
    });
    // eslint-disable-next-line
  }, [changedParams.limit, changedParams.page, searchString]);

  //get product api
  const getProductsList = async ({ page, limit, productName = "" }) => {
    setProductLoading(true);
    const details = {
      page: page,
      limit: limit,
      productName: productName, 
      category: "Computer Table"
    };
    console.log(details);

    await axios
      .all([getAdminProduct(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let total = resData?.data?.total;
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data?.data : [];
            //console.log(resData);
            setProducts(resData);
            setTotalCount(total);
            setProductLoading(false);
          } else {
            setProductLoading(false);
          }
        }
        setProductLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in retreving Products");
      });
  };

  const deleteProduct = async (values) => {
    setDeleteLoading(true);

    const details = {
      product_id: values?._id,
    };

    await axios
      .all([deleteProductById(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            notifySuccess("Product Deleted Successfully");
            toggleDeleteModal();
            getProductsList({ page: pagination.page, limit: pagination.limit });
            setDeleteLoading(false);
          } else {
            if (message) notifyWarn(message);
            setDeleteLoading(false);
          }
        } else {
          setDeleteLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        notifyError(res.message);
        setDeleteLoading(false);
        if (res.message)
          console.log("An error occurred while Deleting Product");
      });

    setProductLoading(false);
  };

  const actions = (row) => {
    if (!row) return;
    return (
      <div className="action-btn-main">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(row._id)}
        >
          <TbEye data-tooltip-id="view-tool-tip" size={20} color="#005478" />
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate("/admin/products/edit-product", {
              state: { selectedProduct: row },
            })
          }
        >
          <img
            data-tooltip-id="edit-tool-tip"
            src={editicon}
            alt="edit"
            className="action-icon"
          />
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            toggleDeleteModal(row);
          }}
        >
          <img
            data-tooltip-id="delete-tool-tip"
            src={deleteicon}
            alt="delete"
            className="action-icon"
          />
        </div>
      </div>
    );
  };

  const handleRowClick = (id) => {
    navigate({
      pathname: `/admin/products/product-details`,
      search: `productId=${id}`,
    });
  };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    deBounce((value) => {
      setSearchString(value);
    }, 1000),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  return (
    <div className="main-product">
      <div className="product-header">
        <div className="search-container">
          <GoSearch className="search-icon" size={22} />
          <input
            type="text"
            placeholder="Search Product name..."
            onChange={handleSearchChange}
          />
        </div>

        <div className="add-product">
          <button onClick={() => navigate("/admin/products/add-product")}>
            <img src={ProductSVG} alt="" />
            Add New Product
          </button>
        </div>
      </div>
      {productLoading ? (
        <>
          <LazyLoader />
        </>
      ) : (
        <div className="product-table">
          {products && products.length > 0 ? (
            <>
              <table border={1}>
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th className="td-text-center">Quantity</th>
                    <th className="td-text-center">Price</th>

                    <th className="td-text-center">Offer Price</th>
                    <th className="td-text-center">Discount</th>
                    <th className="td-text-center">Category</th>
                    {/*   <th className="td-text-center">In Stock</th>  */}
                    <th className="td-text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((item) => (
                    <tr key={item?._id}>
                      <td
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: ".45rem",
                        }}
                      >
                        <div className="product-img-cont">
                          <img
                            src={item?.images[0]}
                            alt=""
                            className="product-image"
                          />
                        </div>
                        <p
                          style={{
                            marginBottom: "0",
                            textTransform: "capitalize",
                            fontWeight: "600",
                          }}
                        >
                          {item?.name}
                        </p>
                      </td>
                      <td className="td-text-center">{item?.quantity}</td>
                      <td className="td-text-center">
                        {item?.price?.toLocaleString()}
                      </td>

                      <td className="td-text-center">
                        {item?.offer_price?.toLocaleString()}
                      </td>
                      <td className="td-text-center">{item?.discount}</td>
                      <td className="td-text-center">{item?.category?.categoryName}</td>
                      {/*  <td className="td-text-center">
                        <CheckBox checked={item?.in_stock} />
                      </td>  */}
                      <td className="td-text-center">{actions(item)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div className="no-products-div">
              <h5 style={{ fontFamily: "Montserrat-Medium" }}>
                {" "}
                No Products found!
              </h5>
            </div>
          )}
        </div>
      )}
      {products && products.length > 0 ? (
        <>
          <CustomPagination
            currentPage={pagination.page}
            rowsPerPage={pagination.limit}
            totalCount={totalCount}
            onPageChange={onPageChange}
            onRowsPerPage={onRowsChange}
          />
        </>
      ) : (
        <></>
      )}

      <ReactTooltip
        id="view-tool-tip"
        place="bottom"
        variant="info"
        content="Product details"
      />
      <ReactTooltip
        id="edit-tool-tip"
        place="bottom"
        variant="info"
        content="Edit Product"
      />
      <ReactTooltip id="delete-tool-tip" place="bottom" content="Delete" />
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Delete Product</ModalHeader>
        <ModalBody>
          <div className="delete-modal-container">
            <img src={deleteicon} alt="" />
            <h6>
              Are you sure you want to delete this product{" "}
              <span className="del-prod-name">
                {selectedDeleteProduct?.name}
              </span>
              ?
            </h6>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            style={{ width: "120px" }}
            onClick={() => deleteProduct(selectedDeleteProduct)}
          >
            {deleteLoading ? <ButtonLoader /> : "Delete"}
          </Button>

          <Button
            color="secondary"
            style={{ width: "120px" }}
            onClick={toggleDeleteModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Products;
