import FetchServices from "./FetchServices";
import apiService from "./ApiServices";
import CustomerFetchServices from "./CustomerFetchServices";

export default function MainServices() {
  const { AxiosService } = FetchServices();
  const { CustomerAxiosService } = CustomerFetchServices();
  const Services = {
    //admin functions
    adminSignin: function (details) {
      let API = apiService.adminSignin;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, false, type).then((res) => {
        return res;
      });
    },
    updatePassword: function (details) {
      let API = apiService.updatePassword;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    addProduct: function (details) {
      let API = apiService.addProduct;
      let payload = details;
      let type = "POST";
      return AxiosService(API, payload, true, type, true).then((res) => {
        return res;
      });
    },
    getAdminProduct: function (details) {
      let API = apiService.getAdminProduct;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getProducts: function (details) {
      let API = apiService.getProducts;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getProductsStats: function (details) {
      let API = apiService.getProductsStats;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getProductById: function (details) {
      let API = apiService.getProductById;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    updateProductById: function (details) {
      let API =
        apiService.updateProductById + "?productId=" + details?.product_id;

      let payload = { ...details };

      let type = "POST";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    deleteProductById: function (details) {
      let API =
        apiService.deleteProductById + "?productId=" + details.product_id;

      let payload = {
        ...details,
      };
      let type = "POST";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    getUsersCount: function (details) {
      let API = apiService.getUsersCount;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    getAllPincodes: function (details) {
      let API = apiService.getAllPincodes;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    addPincode: function (details) {
      let API = apiService.addPincode;

      let payload = { ...details };

      let type = "POST";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    deletePincode: function (details) {
      let API = apiService.deletePincode + "?pincodeId=" + details.pincodeId;

      let payload = {
        ...details,
      };
      let type = "DELETE";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getAllOrder: function (details) {
      let API = apiService.getAllOrder;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    getAdminOrderById: function (details) {
      let API = apiService.getAdminOrderById;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    updateOrderStatus: function (details) {
      let API = apiService.updateOrderStatus;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getSalesAmount: function (details) {
      let API = apiService.getSalesAmount;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getStatsOfAllOrderStatus: function (details) {
      let API = apiService.getStatsOfAllOrderStatus;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getSalesStatsByMonth: function (details) {
      let API = apiService.getSalesStatsByMonth;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },
    getSalesStatsByYear: function (details) {
      let API = apiService.getSalesStatsByYear;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    getAllCategories: function (details) {
      let API = apiService.getAllCategories;

      let payload = {
        ...details,
      };
      let type = "GET";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    addCategory: function (details) {
      let API = apiService.addCategory;

      let payload = details;
      let type = "POST";

      return AxiosService(API, payload, true, type, true).then((res) => {
        return res;
      });
    },

    deleteCategory: function (details) {
      let API = apiService.deleteCategory;

      let payload = {
        ...details,
      };
      let type = "DELETE";

      return AxiosService(API, payload, true, type).then((res) => {
        return res;
      });
    },

    /* Customer apis */
    addCustomer: async function (token) {
      let API = apiService.addCustomer;
      let payload = token;
      let type = "POST";
      return await CustomerAxiosService(API, payload, false, type).then(
        (res) => {
          return res;
        }
      );
    },
    addItemToCart: async function (details) {
      let API = apiService.addItemToCart;
      let payload = { ...details };
      console.log("payload", payload);
      let type = "POST";
      return await CustomerAxiosService(API, payload, false, type).then(
        (res) => {
          return res;
        }
      );
    },
    getUser: async function (details) {
      let API = apiService.getUser;
      const payload = {
        ...details,
      };
      let type = "GET";
      return await CustomerAxiosService(API, payload, true, type).then(
        (res) => {
          return res;
        }
      );
    },
    getCartItem: async function (details) {
      let API = apiService.getCartItem;
      const payload = {
        ...details,
      };
      let type = "GET";
      return await CustomerAxiosService(API, payload, true, type).then(
        (res) => {
          return res;
        }
      );
    },
    deleteCartItem: async function (details) {
      let API = apiService.deleteCartItem + "?cartId=" + details.cartId;
      const payload = {
        ...details,
      };
      let type = "POST";
      return await CustomerAxiosService(API, payload, true, type).then(
        (res) => {
          return res;
        }
      );
    },

    updateCartItem: async function (details) {
      let API = apiService.updateCartItem;
      const payload = {
        ...details,
      };
      let type = "POST";
      return await CustomerAxiosService(API, payload, true, type).then(
        (res) => {
          return res;
        }
      );
    },

    placeOrder: async function (details) {
      let API = apiService.placeOrder;
      const payload = {
        ...details,
      };
      let type = "POST";
      return await CustomerAxiosService(API, payload, true, type).then(
        (res) => {
          return res;
        }
      );
    },
    getAddressOfUser: async function (details) {
      let API = apiService.getAddressOfUser;
      const payload = {
        ...details,
      };
      let type = "GET";
      return await CustomerAxiosService(API, payload, true, type).then(
        (res) => {
          return res;
        }
      );
    },
    updateAddressOfUser: async function (details) {
      let API =
        apiService.updateAddressOfUser + "?addressId=" + details.addressId;
      console.log(API);
      const payload = {
        ...details,
      };
      let type = "POST";
      return await CustomerAxiosService(API, payload, true, type).then(
        (res) => {
          return res;
        }
      );
    },
    getOrderOfUser: async function (details) {
      let API = apiService.getOrderOfUser;
      const payload = {
        ...details,
      };
      let type = "GET";
      return await CustomerAxiosService(API, payload, true, type).then(
        (res) => {
          return res;
        }
      );
    },
    confirmOrder: async function (details) {
      let API = apiService.confirmOrder;
      console.log(API);
      const payload = {
        ...details,
      };
      let type = "POST";
      return await CustomerAxiosService(API, payload, true, type).then(
        (res) => {
          return res;
        }
      );
    },
    getPaymentStatus: async function (details) {
      let API =
        apiService.getPaymentStatus + "?payment_id=" + details.payment_id;
      const payload = {
        ...details,
      };
      let type = "GET";
      return await CustomerAxiosService(API, payload, true, type).then(
        (res) => {
          return res;
        }
      );
    },
  };
  return Services;
}
