/* import React, { createContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";

let initialState = {};

const ConfigContext = createContext({
  ...initialState,
});

const getConfig = () => {
  axios
    .get("/config/appConfig.json")
    .then((res) => {
      let appConfig = res.data;
      initialState = appConfig;
    })
    .catch((err) => {
      if (err) console.log(err);
    });
};
getConfig();

ConfigProvider.propTypes = {
  children: PropTypes.node,
};

function ConfigProvider({ children }) {
  return (
    <ConfigContext.Provider
      value={{
        ...initialState,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigContext, ConfigProvider };
 */

import React, { createContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

const ConfigContext = createContext();

const ConfigProvider = ({ children }) => {
  const [initialState, setInitialState] = useState({});
  const [status, setStatus] = useState("initial");

  useEffect(() => {
    const getConfig = async () => {
      try {
        setStatus("loading");
        const res = await axios.get("/config/appConfig.json");
        setInitialState(res.data);
        setStatus("success");
      } catch (err) {
        console.error(err);
        setStatus("error");
      }
    };

    getConfig();
  }, []);

  const value = useMemo(() => {
    return {
      ...initialState,
      status,
    }
  }, [initialState, status]);

  return (
    <ConfigContext.Provider value={value}>
      {children}
    </ConfigContext.Provider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.node,
};

export { ConfigContext, ConfigProvider };
