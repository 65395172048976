import React, { useEffect, useState } from "react";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "./Cart.scss";
import QuantitySelector from "../../../../assets/elements/Customer/QuantitySelector";
import { IoCloseSharp } from "react-icons/io5";
import { CustomerLazyLoader } from "../../../../assets/loaders";
import { CartState } from "../../../../context/CustomerCart";

import * as Storage from "../../../../store/LocalStorage";
import useCustomerConfig from "../../../../hooks/useCutomerConfig";

import { RiShoppingCart2Line } from "react-icons/ri";
import MainServices from "../../../../services/MainServices";
import axios from "axios";

const Cart = () => {
  const [loading, setLoading] = useState(false); 
  const [cartItems, setCartItems] = useState([]);
  const [cartAmount, setCartAmount] = useState();

  const {
    state: { cart },
    dispatch,
  } = CartState();

  //console.log(cart);

  const { getCartItem, deleteCartItem, updateCartItem } = MainServices();

  const navigate = useNavigate();

  const config = useCustomerConfig();

  useEffect(() => {
    getCartOfUser();
    // eslint-disable-next-line
  }, []);

  //get cart api
  const getCartOfUser = async () => {
    setLoading(true);

    try {
      const details = {};
      const res = await axios.all([getCartItem(details)]);
      if (res[0]) {
        let resData = res[0].data.data ? res[0].data.data.cart : [];
        console.log(resData);
        setCartAmount(res[0].data?.data?.totalCartAmount);
        setCartItems(resData);
      }
    } catch (error) {
      console.error("An error occurred in retrieving Carts", error);
    } finally {
      setLoading(false);
    }
  };

  //delete cart
  const handleDeleteCartItem = async (item) => {
    const details = {
      cartId: item?._id,
    };
    await axios
      .all([deleteCartItem(details)])
      .then(function (res) {
        console.log(res);
        getCartOfUser();
        Storage.removeCartItem(config.customerCartItems, item);
        dispatch({
          type: "REMOVE_FROM_CART",
          payload: item,
        });
        getCartOfUser();

      //  window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("cart: ", cart);

  const updateCartQuantity = async (cartId, quantity) => {
    const details = {
      cartId,
      quantity,
    };
    await axios
      .all([updateCartItem(details)])
      .then(function (res) {
        console.log(res);
        getCartOfUser();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const increaseQuantity = (cartId, quantity) => {
    const newQuantity = quantity + 1;
    updateCartQuantity(cartId, newQuantity);
  };

  const decreaseQuantity = (cartId, quantity) => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      updateCartQuantity(cartId, newQuantity);
    }
  };

  return (
    <div className="main-cart-container">
      <IoChevronBackCircleOutline
        className="back-btn"
        onClick={() => navigate(-1)}
        size={35}
      />
      <div className="cart-content">
        {loading ? (
          <>
            <CustomerLazyLoader />
          </>
        ) : cartItems && cartItems.length > 0 ? (
          <>
            <div className="items-table">
              <table>
                <thead>
                  <tr>
                    <th
                      style={{
                        borderTopLeftRadius: "14px",
                        borderBottomLeftRadius: "14px",
                      }}
                    >
                      Product
                    </th>
                    <th>Offer Price</th>
                    <th style={{ textAlign: "center" }}>Quantity</th>
                    <th
                      style={{
                        borderTopRightRadius: "14px",
                        borderBottomRightRadius: "14px",
                      }}
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <CustomerLazyLoader />
                  ) : (
                    <>
                      {cartItems.map((item, index) => (
                        <tr key={item._id}>
                          <td data-label="Product">
                            <div className="product-container">
                              <IoCloseSharp
                                size={25}
                                style={{
                                  cursor: "pointer",
                                  marginRight: ".1rem",
                                }}
                                onClick={() => {
                                  handleDeleteCartItem(item);
                                }}
                              />
                              <div
                                onClick={() =>
                                  navigate({
                                    pathname: "/products/product-details",
                                    search: `productId=${item?.product_id?._id}`,
                                  })
                                }
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                <div className="product-image">
                                  <img
                                    src={item?.product_id?.images[0]}
                                    alt="product-img"
                                  />
                                </div>

                                <div>
                                  <h6>{item?.product_id?.name}</h6>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td
                            data-label="Price"
                            style={{
                              fontFamily: "Montserrat-Medium",
                              fontWeight: "600",
                              textAlign: "center",
                            }}
                          >
                            <div style={{ marginTop: "2rem" }}>
                              {item.product_id?.offer_price?.toLocaleString()}
                            </div>
                          </td>
                          <td data-label="Quantity">
                            {console.log("item: ", item)}
                            <div className="qunatity-container-div">
                              <QuantitySelector
                                quantity={item?.quantity}
                                cartId={item._id}
                                increaseQuantity={increaseQuantity}
                                decreaseQuantity={decreaseQuantity}
                              />
                            </div>
                          </td>
                          <td
                            data-label="Total"
                            style={{
                              fontFamily: "Montserrat-Medium",
                              fontWeight: "600",
                            }}
                          >
                            <div style={{ marginTop: "2rem" }}>
                              {item?.total_price?.toLocaleString()}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>

            <div className="order-summary">
              <h5 className="summary-heading">Order Summary</h5>

              <div className="summary-items">
                <p>Items</p>
                <h5>{cart?.length}</h5>
              </div>

              {console.log("cart for order summary: ", cartAmount)}

              <div className="summary-items">
                <p>Price</p>
                <h5>{cartAmount.toLocaleString()}</h5>
              </div>
              <div
                className="summary-items"
                style={{
                  borderBottom: "1px solid #BABABA",
                  paddingBottom: "2rem",
                }}
              >
                <p>Shipping</p>
                <h5 style={{ color: "#00770c", fontSize: "16px" }}>FREE</h5>
              </div>
              {/*  <div
                className="summary-items"
                style={{
                  borderBottom: "1px solid #BABABA",
                  paddingBottom: "2rem",
                }}
              >
                <p>Taxes</p>
                <h5>18%</h5>
              </div> */}

              <div className="summary-items" style={{ paddingTop: "2rem" }}>
                <p>Total</p>
                <h5>{cartAmount.toLocaleString()} INR</h5>
              </div>

              <button
                className="proceed-to-checkout-btn"
                onClick={() =>
                  navigate("/cart/billing-details", {
                    state: { cart, cartAmount },
                  })
                }
              >
                Proceed to Checkout
              </button>
            </div>
          </>
        ) : (
          <div className="empty-cart">
            <div className="cart-circle">
              <RiShoppingCart2Line size={83} color="#fff" />
            </div>

            <h6>
              Your Cart is Empty!{" "}
              <span
                style={{
                  textDecoration: "underline",
                  color: "rgb(65, 133, 63)",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/categories")}
              >
                Continue Shopping
              </span>
            </h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
