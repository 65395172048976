import React, { useState, useEffect, useRef } from "react";
import "./ProductDetails.scss";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import MainServices from "../../../../../services/MainServices";
import { LazyLoader } from "../../../../../assets/loaders";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ButtonLoader } from "../../../../../assets/loaders";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators, 
} from "reactstrap";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import moment from "moment";
import editicon from "../../../../../assets/images/img/edit-curved.svg";
import deleteicon from "../../../../../assets/images/img/delete-curved.svg";

import { HiDotsHorizontal } from "react-icons/hi";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../../../assets/elements/Toaster";

const ProductDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const product_id = new URLSearchParams(location.search).get("productId");

  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionToggle, setActionToggle] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [imageItems, setImageItems] = useState([]);

  console.log(imageItems);

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const actionRef = useRef(null);

  const { getProductById, deleteProductById } = MainServices();

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === imageItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? imageItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = imageItems?.map((image, index) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={index}
    >
      <img src={image} className="product-images" alt={`Slide ${index + 1}`} />
    </CarouselItem>
  ));

  //get Prodcut by id api
  const getProductItemById = async (productId) => {
    setLoading(true);
    const details = {
      productId: productId,
    };

    await axios
      .all([getProductById(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];

          if (resData.success) {
            resData = resData.data ? resData?.data?.data : [];
            // console.log(resData);
            setProduct(resData);
            setImageItems(resData?.images);
            setLoading(false);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in retreving Product");
      });
  };

  const deleteProduct = async (values) => {
    setDeleteLoading(true);

    const details = {
      product_id: values?._id,
    };

    await axios
      .all([deleteProductById(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            notifySuccess("Product Deleted Successfully");
            toggleDeleteModal();
            navigate("/admin/products");
            setDeleteLoading(false);
          } else {
            if (message) notifyWarn(message);
            setDeleteLoading(false);
          }
        } else {
          setDeleteLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        notifyError(res.message);
        setDeleteLoading(false);
        if (res.message)
          console.log("An error occurred while Deleting Product");
      });

    setDeleteLoading(false);
  };

  useEffect(() => {
    getProductItemById(product_id);
    // eslint-disable-next-line
  }, []);

  const formatDate = (dateString) => {
    return moment(dateString).format("DD MMMM YYYY");
  };

  const onActionToggle = () => {
    setActionToggle((prev) => !prev);
  };

  const onProductNavigate = () => {
    navigate("/admin/products/edit-product", {
      state: { selectedProduct: product },
    });
  };

  return (
    <div>
      {loading ? (
        <>
          <LazyLoader />
        </>
      ) : (
        <div className="main-product-details-container">
          <div className="content-container">
            <div className="details-header">
              <div className="back-button">
                <IoArrowBackCircleOutline
                  className="back-icon"
                  color="#000"
                  stroke="#000"
                  size={40}
                  onClick={() => navigate(-1)}
                />
              </div>
              <h6>Images</h6>
              <div className="more-options-box">
                <div className="action-list-icon" onClick={onActionToggle}>
                  <HiDotsHorizontal
                    size={33}
                    style={{ marginRight: ".5rem" }}
                  />{" "}
                  Options
                </div>
                {actionToggle && (
                  <div className="actions-list" ref={actionRef}>
                    <div className="action edit" onClick={onProductNavigate}>
                      <span>Edit</span>
                      <img src={editicon} alt="edit" className="action-icon" />
                    </div>
                    <hr className="actions-hr" />
                    <div class="action delete" onClick={toggleDeleteModal}>
                      <span>Delete</span>
                      <img
                        src={deleteicon}
                        alt="edit"
                        className="action-icon"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="image-container">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={imageItems}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                  color="#000"
                />
                {slides}
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                />
              </Carousel>
            </div>
            <div className="product-info">
              <div className="info-data">
                <span>Product Name:</span>
                <h6>{product?.name}</h6>
              </div>
              <div className="info-data">
                <span>Quantity:</span>
                <h6>{product?.quantity}</h6>
              </div>
              <div className="info-data">
                <span>Price:</span>
                <h6>{product?.price?.toLocaleString()}</h6>
              </div>
              <div className="info-data">
                <span>Offer Price:</span>
                <h6>{product?.offer_price?.toLocaleString()}</h6>
              </div>

              <div className="info-data">
                <span>Discount:</span>
                <h6>{product?.discount}%</h6>
              </div> 
              <div className="info-data">
                <span>Status:</span>
                <h6>{product?.status}</h6>
              </div>
              <div className="info-data">
                <span>Created At:</span>
                <h6>{formatDate(product?.createdAt)}</h6>
              </div>
              <div className="info-data">
                <span>Updated At:</span>
                <h6>{formatDate(product?.updatedAt)}</h6>
              </div>

              <div className="info-data description">
                <span>Description:</span>
                <h6>{product?.description}</h6>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Delete Product</ModalHeader>
        <ModalBody>
          <div className="delete-modal-container">
            <img src={deleteicon} alt="" />
            <h6>
              Are you sure you want to delete this product{" "}
              <span className="del-prod-name">{product?.name}</span>?
            </h6>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            style={{ width: "120px" }}
            onClick={() => deleteProduct(product)}
          >
            {deleteLoading ? <ButtonLoader /> : "Delete"}
          </Button>

          <Button
            color="secondary"
            style={{ width: "120px" }}
            onClick={toggleDeleteModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProductDetails;
