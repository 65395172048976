import React, { useState, useEffect } from "react";
import "./EditProducts.scss";
import { useLocation, useNavigate } from "react-router-dom";

import { IoArrowBackCircleSharp } from "react-icons/io5";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import ErrorMessage from "../../../../../assets/elements/ErrorMessage";
import PrimaryButton from "../../../../../assets/elements/PrimaryButton";
import { FormGroup, Input, Label } from "reactstrap";
import MainServices from "../../../../../services/MainServices";
import axios from "axios";
import {
  notifySuccess,
  notifyWarn,
} from "../../../../../assets/elements/Toaster";

const addProductSchema = Yup.object().shape({
  name: Yup.string().required("Title is Required"),
  price: Yup.number().required("Price is required"),
  offer_price: Yup.number().required("Offer Price is required"),
  quantity: Yup.number().required("Quantity is required"),
  //in_stock: Yup.mixed().required("Required"),
  // shipping_included: Yup.boolean().required("Required"),
  files: Yup.mixed().test(
    "fileList",
    "At least one image is required",
    (value) => {
      const currentImageArray = Object.entries(value);
      return currentImageArray.length > 0;
    }
  ),
  description: Yup.string().required("Description is Required"),
  category: Yup.mixed().required("This is required field"),
});

const EditProducts = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const selectedProduct = location.state?.selectedProduct;

  const [editLoading, setEditLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const { updateProductById, getAllCategories } = MainServices();

  //update product by ID
  const updateProduct = async (values) => {
    setEditLoading(true);

    const details = {
      product_id: selectedProduct?._id,
      name: values.name,
      description: values.description,
      price: values.price,
      offer_price: values.offer_price,
      featured_product: values.featured_product,
      category: values.category,
      banner_image: "bannerImage",
      quantity: values.quantity,
      //   shipping_included: values.shipping_included,
      status: values.status,
    };

    console.log(values);

    await axios.all([updateProductById(details)]).then(function (res) {
      if (res[0]) {
        let resData = res[0];
        let message = resData.message;
        if (resData.success) {
          navigate("/admin/products");
          notifySuccess("Product Updated Successfully");
          setEditLoading(false);
        } else {
          if (message) notifyWarn(message);
          setEditLoading(false);
        }
      } else {
        setEditLoading(false);
      }
    });

    setEditLoading(false);
  };

  const getCategoriesList = async () => {
    const details = {};

    await axios
      .all([getAllCategories(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let total = resData?.data?.total;
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data?.data : [];
            //console.log(resData);
            setCategories(resData);
          } else {
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in retreving categories");
      });
  };

  useEffect(() => {
    getCategoriesList();
  }, []);

  return (
    <div className="main-editproduct-container">
      <div className="back-button">
        <IoArrowBackCircleSharp
          className="back-icon"
          size={40}
          onClick={() => navigate(-1)}
        />
      </div>

      <div className="addproduct-form">
        <Formik
          initialValues={{
            name: selectedProduct?.name || "",
            price: selectedProduct?.price || "",
            offer_price: selectedProduct?.offer_price || "",
            description: selectedProduct?.description || "",
            quantity: selectedProduct?.quantity || "",
            featured_product: selectedProduct?.featured_product || false,
            files: selectedProduct?.images,
            //   shipping_included: selectedProduct?.shipping_included || false,
            banner_image: selectedProduct?.banner_image || "image",
            category: selectedProduct?.category || "",
          }}
          onSubmit={(values) => {
            updateProduct(values);
          }}
          validationSchema={addProductSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            touched,
          }) => (
            <Form>
              <FormGroup>
                <Label className="input-headings">
                  Edit Name of the Product:{" "}
                </Label>
                <Input
                  className="text-input add-product-input"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Name of the product"
                />
                <ErrorMessage errors={errors} touched={touched} name="name" />
              </FormGroup>

              <FormGroup>
                <Label className="input-headings">Category :</Label>
                <Input
                  type="select"
                  name="category"
                  className="text-input stock-select"
                  value={values.category}
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  {categories?.map((cat) => {
                    return (
                      <option key={cat._id} value={cat._id}>
                        {cat.categoryName}
                      </option>
                    );
                  })}
                </Input>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="in_stock"
                />
              </FormGroup>

              <FormGroup>
                <Label className="input-headings">Product's Price: </Label>
                <Input
                  className="text-input add-product-input-price"
                  name="price"
                  type="number"
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Price of the product"
                />
                <ErrorMessage errors={errors} touched={touched} name="price" />
              </FormGroup>

              <FormGroup>
                <Label className="input-headings">Offer Price: </Label>
                <Input
                  className="text-input add-product-input-price"
                  name="offer_price"
                  type="number"
                  value={values.offer_price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Offer of the product"
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="offer_price"
                />
              </FormGroup>

              <FormGroup>
                <Label className="input-headings">Quantity: </Label>
                <Input
                  className="text-input add-product-input-price"
                  name="quantity"
                  type="number"
                  value={values.quantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Quantity of the product"
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="quantity"
                />
              </FormGroup>

              <FormGroup>
                <Label className="input-headings">Featured Product:</Label>
                <Input
                  type="select"
                  name="featured_product"
                  className="text-input stock-select"
                  value={values.featured_product}
                  onChange={handleChange}
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Input>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="featured_product"
                />
              </FormGroup>

              {/*  <FormGroup>
                <Label className="input-headings">Shipping Included:</Label>
                <Input
                  type="select"
                  name="shipping_included"
                  className="text-input stock-select"
                  value={values.shipping_included}
                  onChange={handleChange}
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Input>
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="shipping_included"
                />
              </FormGroup> */}

              <FormGroup>
                <Label className="input-headings">Product Description: </Label>{" "}
                <br />
                <textarea
                  className="text-input add-product-description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter description"
                />
                <ErrorMessage
                  errors={errors}
                  touched={touched}
                  name="description"
                />
              </FormGroup>

              {/*  <FormGroup>
                <Label className="input-headings">Product's Image: </Label>
                <div className="wrap-custom-file">
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    name="files"
                    multiple
                    onChange={(e) => {
                      handleChooseImage(e);
                      setFieldValue("image", e.target.files || []);
                    }}
                    className="custom-drag-drop"
                  />

                  <label htmlFor="file-upload">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 0,
                      }}
                    >
                      <img src={FolderSVG} width={68} height={68} alt="" />
                      <p>Drag & Drop your Image</p>
                    </div>
                  </label>
                </div>
                <ErrorMessage errors={errors} touched={touched} name="files" />

                <div className="selected-images-container">
                  {Object.entries(currentImage).map(([key, image], index) => (
                    <div key={index} className="selected-image">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Selected Image ${index}`}
                      />

                      <IoMdClose
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const updatedImages = { ...currentImage };
                          delete updatedImages[key];
                          setCurrentImage(updatedImages);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </FormGroup> */}

              <div className="login-btn">
                <PrimaryButton
                  style={{
                    width: "130px",
                    padding: ".650rem 0",
                    fontFamily: "Montserrat-Regular",
                    background: "#005478",
                    fontSize: "1rem",
                    borderRadius: "22px",
                  }}
                  type="submit"
                  title="Update"
                  isLoading={editLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditProducts;
