import React from 'react';
import "./AboutUs.scss";

import Line from "../../../../assets/images/img/cus-line.svg"

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-header">
        <h5>About Us</h5>
      </div>
      <div className="about-us-content">
        <div className="about-us-headings">
          <div>
            <h6>
              <img src={Line} alt="" />
              Introduction
            </h6>
            <p>
              Welcome to Furniture King, your one-stop destination for
              high-quality furniture and home decor. We're passionate about
              creating comfortable and stylish living spaces that reflect your
              unique taste.
            </p>
          </div>

          <div>
            <h6>
              <img src={Line} alt="" />
              What Sets Us Apart
            </h6>
            <ol className='ol-padding'>
              <li>
                <p>
                  {" "}
                  <strong>Quality Craftsmanship : </strong> We collaborate with
                  skilled artisans who take pride in their work. Each piece is
                  meticulously crafted to ensure durability and aesthetics.
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  <strong>Variety : </strong> Whether you're furnishing a cozy
                  apartment or a spacious villa, we have something for everyone.
                  Explore our collection of sofas, beds, dining sets, and more.
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  <strong>Customer-Centric Approach : </strong> Your
                  satisfaction is our priority. Our friendly customer support
                  team is here to assist you at every step, from choosing the
                  right piece to after-sales service.
                </p>
              </li>
            </ol>
          </div>

          <h6>
            <img src={Line} alt="" />
            Our Story
          </h6>

          <h4>
            Crafted Comfort:{" "}
            <span style={{ color: "rgba(0, 119, 12, 1)" }}>
              {" "}
              Quality materials, Enduring Designs
            </span>{" "}
          </h4>
        </div>
        <div className="about-cards-container">
          <div className="about-cards content">
            <h6>
              Furniture King was founded in Sangareddy with a vision to
              transform houses into homes. Our journey began with a small
              showroom, and over the years, we've grown into a trusted online
              furniture retailer. We curate a wide range of furniture pieces,
              from classic to contemporary, to suit every lifestyle.
            </h6>
          </div>
          <div className="about-cards">
            <p>Image</p>
          </div>
        </div>
        <div className="about-cards-container">
          <div className="about-cards">
            <p>Image</p>
          </div>{" "}
          <div className="about-cards">
            <p>Image</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs